export class RequirementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Requirement
     * :param req_id: ID of the Requirement
 * :return: JSON object of Requirement data
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement(reqId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Vcrm
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementVcrm(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/vcrm',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Trace
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementTrace(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Analyse Impact
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    analyseImpact(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/impact_analysis',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Issues
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementIssues(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/issues',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Tests
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementTests(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/tests',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement History
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementHistory(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/history',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
