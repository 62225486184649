export class NotificationsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    getNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Put Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    putNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Create Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    createNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Set Notification Read
     * Set a single Notification by its ID to "has been read"
 * :param _notification_id:
 * :return:
     * @param notificationId
     * @returns any Successful Response
     * @throws ApiError
     */
    setNotificationRead(notificationId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/notifications/{_notification_id}',
            path: {
                '_notification_id': notificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
