import coreService from '@/libs/api-services/core-service'
import router from '@/router'

const getDefaultState = () => ({
  interfaces: [],
  selectedInterface: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_INTERFACES: (state, data) => { state.interfaces = data },
    SET_SELECTED_INTERFACE: (state, data) => { state.selectedInterface = data },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearInterfaces: ({ commit }) => { commit('CLEAR_ALL') },
    fetchInterfaces: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/interfaces', { params })
        .then(({ data }) => commit('SET_INTERFACES', data))
        .catch(e => console.error(e))
    },
    createInterface: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post('/v1/legacy/interfaces/create', data, { params })
        .then(() => dispatch('fetchInterfaces'))
        .catch(e => console.error(e))
    },
    selectInterface: ({ commit }, interfaceId) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/interfaces/${interfaceId}`, { params })
        .then(({ data }) => commit('SET_SELECTED_INTERFACE', data))
        .catch(e => console.error(e))
    },
    updateInterface: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post(`/v1/legacy/interfaces/update/${data.id}`, data, { params })
        .then(() => dispatch('fetchInterfaces'))
        .catch(e => console.error(e))
    },
    deleteInterface: ({ dispatch }, interfaceId) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/interfaces/${interfaceId}`, { params })
        .then(() => dispatch('fetchInterfaces'))
        .catch(e => console.error(e))
    },
  },
}
