export class ModelApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Model Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createModelSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Temp Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTempModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/temp_model',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    downloadSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}/download',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Temp Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getTempModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/temp',
        });
    }
    /**
     * Get Models Shared
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsShared() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/shared',
        });
    }
    /**
     * Get Models Public
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsPublic() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/public',
        });
    }
    /**
     * Get Models By User
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsByUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/user',
        });
    }
    /**
     * Get All Components
     * @param modelId
     * @param subtype
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponents(modelId, subtype) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/components',
            path: {
                'model_id': modelId,
            },
            query: {
                'subtype': subtype,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getModel(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Model Properties
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateModelProperties(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model Stats
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelStats(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/model/{model_id}/stats',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model Owners
     * @param modelId
     * @returns string Successful Response
     * @throws ApiError
     */
    getModelOwners(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/model/{model_id}/owners',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Model Owners
     * @param modelId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    addModelOwners(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/model/{model_id}/owners',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Model Owners
     * @param modelId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    removeModelOwners(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/model/{model_id}/owners',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model Shared Users
     * @param modelId
     * @returns string Successful Response
     * @throws ApiError
     */
    getModelSharedUsers(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/model/{model_id}/share',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Share Model With Users
     * @param modelId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    shareModelWithUsers(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/model/{model_id}/share',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unshare Model With Users
     * @param modelId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    unshareModelWithUsers(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/model/{model_id}/share',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
