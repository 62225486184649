import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  functions: [],
  resources: [],
  performers: [],
  systems: [],
  capabilities: [],
  standards: [],
  objectives: [],
  benefits: [],
  kpis: [],
  stakeholders: [],
  assumptions: [],
  dependencies: [],
  project_constraints: [],
})

function mapToSelectElement(array, value, text) {
  /**
   * Map an array to be compatible with HTML select element,
   * while retaining all original values.
   * @function mapToSelectElement
   * @private
   * @param   {Array}   array The array to map.
   * @param   {String}  value The object property to map to the `value` property.
   * @param   {String}  text  The object property to map to the `text` property.
   * @return  {Array}   The original array with mapped `value` and `text` properties.
   */
  return array.map(el => Object({
    value: el[value],
    text: el[text],
    ...el,
  }))
}

export default {
  namespaced: true,
  state: {
    functions: [],
    resources: [],
    performers: [],
    systems: [],
    capabilities: [],
    standards: [],
    objectives: [],
    benefits: [],
    kpis: [],
    stakeholders: [],
    assumptions: [],
    dependencies: [],
    project_constraints: [],
  },
  getters: {
    functions: state => mapToSelectElement(state.functions, 'id', 'name'),
    resources: state => mapToSelectElement(state.resources, 'id', 'name'),
    performers: state => mapToSelectElement(state.performers, 'id', 'name'),
    systems: state => mapToSelectElement(state.systems, 'id', 'name'),
    capabilities: state => mapToSelectElement(state.capabilities, 'id', 'name'),
    standards: state => mapToSelectElement(state.standards, 'id', 'name'),
    objectives: state => mapToSelectElement(state.objectives, 'id', 'name'),
    benefits: state => mapToSelectElement(state.benefits, 'id', 'name'),
    kpis: state => mapToSelectElement(state.kpis, 'id', 'name'),
    stakeholders: state => mapToSelectElement(state.stakeholders, 'id', 'name'),
    assumptions: state => mapToSelectElement(state.assumptions, 'id', 'name'),
    dependencies: state => mapToSelectElement(state.dependencies, 'id', 'name'),
    project_constraints: state => mapToSelectElement(state.project_constraints, 'id', 'name'),
  },
  mutations: {
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
    SET_FUNCTIONS: (state, data) => { state.functions = data },
    SET_RESOURCES: (state, data) => { state.resources = data },
    SET_PERFORMERS: (state, data) => { state.performers = data },
    SET_SYSTEMS: (state, data) => { state.systems = data },
    SET_CAPABILITIES: (state, data) => { state.capabilities = data },
    SET_STANDARDS: (state, data) => { state.standards = data },
    SET_OBJECTIVES: (state, data) => { state.objectives = data },
    SET_BENEFITS: (state, data) => { state.benefits = data },
    SET_KPIS: (state, data) => { state.kpis = data },
    SET_STAKEHOLDERS: (state, data) => { state.stakeholders = data },
    SET_ASSUMPTIONS: (state, data) => { state.assumptions = data },
    SET_DEPENDENCIES: (state, data) => { state.dependencies = data },
    SET_PROJECT_CONSTRAINTS: (state, data) => { state.project_constraints = data },
  },
  actions: {
    // AS FAR AS I CAN TELL THESE ARE ALL UNUSED
  },
}
