export class ConstantsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Fetch All Constants
     * @returns any Successful Response
     * @throws ApiError
     */
    fetchAllConstants() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/constants',
        });
    }
    /**
     * All Constants
     * @returns any Successful Response
     * @throws ApiError
     */
    allConstants() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/constants',
        });
    }
}
