export class ApprovalsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Approval Request
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalRequest(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/approvals',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
