export class SpecificationsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List Specifications
     * @param modelId
     * @returns Specification Successful Response
     * @throws ApiError
     */
    listSpecifications(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Specification
     * @param modelId
     * @param requestBody
     * @returns Specification Successful Response
     * @throws ApiError
     */
    createSpecification(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Specifications Metadata
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listSpecificationsMetadata(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/metadata',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification
     * @param specId
     * @param modelId
     * @returns Specification Successful Response
     * @throws ApiError
     */
    getSpecification(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Specification
     * @param specId
     * @param modelId
     * @param ifMatch
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSpecification(specId, modelId, ifMatch) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Specification
     * @param specId
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns Specification Successful Response
     * @throws ApiError
     */
    updateSpecification(specId, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Custom Properties
     * @param specId
     * @param modelId
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    getCustomProperties(specId, modelId, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    updateCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    addCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements V2
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsV2(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/requirementsv2',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Issues
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationIssues(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/issues',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Behaviours
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationBehaviours(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/behaviours',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Tests
     * Get all test cases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [tests], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationTests(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/tests',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Releases
     * Get all releases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [releases], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationReleases(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/releases',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Qualification Records
     * Get all Qualification Records linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [qualification_records], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationQualificationRecords(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/qualification_records',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Interfaces
     * Get all interfaces linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [interfaces], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationInterfaces(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/interfaces',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Components
     * Get all components linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [components], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationComponents(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/components',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Trace
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationTrace(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/trace',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Coverage
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationCoverage(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/coverage',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Notes
     * Get all test cases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [tests], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationNotes(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/notes',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Specification
     * Export the given specification.
     * @param specId
     * @param fileFormat
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    exportSpecification(specId, fileFormat, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/export',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'file_format': fileFormat,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Columns
     * @param specId
     * @param modelId
     * @param onlyCustomAttributes
     * @returns string Successful Response
     * @throws ApiError
     */
    getSpecificationColumns(specId, modelId, onlyCustomAttributes = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/columns',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'only_custom_attributes': onlyCustomAttributes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirements(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/requirements',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Specification
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copySpecification(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/copy',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Specification Snapshot
     * @param specId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createSpecificationSnapshot(specId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/snapshot/create',
            path: {
                'spec_id': specId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Rollback Spec To Snapshot
     * @param specId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    rollbackSpecToSnapshot(specId, snapshotId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/snapshot/{snapshot_id}/rollback',
            path: {
                'spec_id': specId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Snapshot
     * @param specId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSnapshot(specId, snapshotId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}/snapshot/{snapshot_id}',
            path: {
                'spec_id': specId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specifications
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecifications(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Specifications
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    postSpecifications(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Options
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationOptions(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/options',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Section Headers
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationSectionHeaders(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/section_headers',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Compare Specification Snapshot
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    compareSpecificationSnapshot(snapshotId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/snapshot_compare/{_snapshot_id}',
            path: {
                '_snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Additional Attributes
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAdditionalAttributes(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/additional_attributes/{spec_id}',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Attributes
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAttributes(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/attributes/{spec_id}',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements Legacy
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsLegacy(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/requirements',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Issues
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getIssues(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/issues',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tests
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTests(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/tests',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Spec
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    copySpec(specId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{spec_id}/copy',
            path: {
                'spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Approval For Specification
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getApprovalForSpecification(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approve Approval Request
     * Approve the ApprovalRequest, and baseline the Specification
 * :param _spec_id: Specification ID to action upon
 * :return: JSON object containing the created Baseline
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approveApprovalRequest(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/approve',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approval Action
     * Reject the ApprovalRequest with reason, and TODO do something
 * :param _spec_id: Specification ID to action upon
 * :return: JSON object containing the updated ApprovalRequest
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalAction(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval/action',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Approval Action
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getApprovalAction(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/action',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Approval
     * Finalise the Specification Review, and start the ApprovalRequest
 * :param _spec_id: Specification ID to action upon
 * :return: JSON object containing the created ApprovalRequest
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    startApproval(specId, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{_spec_id}/approval/start',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approval Reviewers
     * Get all Reviewers for a ApprovalRequest
 * :return Return an array list of Reviewers
 * :rtype JSON Array
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Approval Reviewers
     * Replace all existing Reviewers with the given list of Reviewers
 * :return Return an array list of Reviewers
 * :rtype JSON Array
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putApprovalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Approval Reviewers
     * Add or remove a Reviewer from the existing list
 * Required payload: { username, action(add|remove|decision) }
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    patchApprovalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baselines
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselines(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/baselines',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baseline Compare
     * @param specId
     * @param baselineVersion
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselineCompare(specId, baselineVersion, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/baselines/{_baseline_version}/compare',
            path: {
                '_spec_id': specId,
                '_baseline_version': baselineVersion,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baseline Delete
     * @param specId
     * @param baselineVersion
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselineDelete(specId, baselineVersion, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/specifications/{_spec_id}/baselines/{_baseline_version}/delete',
            path: {
                '_spec_id': specId,
                '_baseline_version': baselineVersion,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshots(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshots',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots Compare
     * @param specId
     * @param snapshotId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshotsCompare(specId, snapshotId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}/compare',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots Compare Requirement
     * @param specId
     * @param snapshotId
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshotsCompareRequirement(specId, snapshotId, reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}/compare/{_req_id}',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
                '_req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Snapshot
     * @param specId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createSnapshot(specId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot',
            path: {
                '_spec_id': specId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Snapshot
     * @param specId
     * @param snapshotId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateSnapshot(specId, snapshotId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Snapshots Simple
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    snapshotsSimple(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshots_simple',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Coverage Table
     * Create a Requirement with a table reporting coverage from <child> back to <parent>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    generateCoverageTable(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/generate_coverage_table',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Interface Table Requirement
     * Create a Requirement with a table reporting the selected Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    generateInterfaceTableRequirement(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/generate_interface_table',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecification1(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Specification
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateSpecification1(specId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}',
            path: {
                '_spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set As Default Specification
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    setAsDefaultSpecification(specId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/specifications/{spec_id}/set_default',
            path: {
                'spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
