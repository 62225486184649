export class AdminCliApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get User Federation Config
     * Get basic details for User Federation config from Keycloak
 * This endpoint, although primarily for Admins, needs to be accessible for all users
 * so that the UI can know what form elements to set as read-only.
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserFederationConfig() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-federation',
        });
    }
    /**
     * Get Identity Providers
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdentityProviders() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/identity-providers',
        });
    }
    /**
     * Get Idp Config
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdpConfig() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/idp-configuration',
        });
    }
    /**
     * Get Password Policy
     * @returns any Successful Response
     * @throws ApiError
     */
    getPasswordPolicy() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/password_policy',
        });
    }
    /**
     * Get Banner
     * @returns any Successful Response
     * @throws ApiError
     */
    getBanner() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/banner',
        });
    }
    /**
     * Update Banner
     * @param message
     * @param ttl
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBanner(message, ttl) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/banner',
            query: {
                'message': message,
                'ttl': ttl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Banner
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBanner() {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/banner',
        });
    }
    /**
     * Delete Session
     * @param sessionId
     * @returns void
     * @throws ApiError
     */
    deleteSession(sessionId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/sessions/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
