import getEnv from '@/utils/env'
import Vue from 'vue'
import _ from 'lodash'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import './registerServiceWorker'
import VueLogger from 'vuejs-logger'

import '@/auth/plugins/keycloak-plugin'
import updateToken from '@/auth/middleware/updateToken'
import coreService from '@/libs/api-services/core-service'

import VueHotkey from 'v-hotkey'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/scss/vue/libs/vue-wizard.scss'
import '@core/assets/fonts/feather/iconfont.css'
import * as Sentry from '@sentry/vue'
import router from './router'
import store from './store'
import App from './App.vue'
import userMixin from '../mixins/UserIdToUsername'

// Global Components
import './global-components'
import './global-directives'

// 3rd party plugins
import '@/libs/font-awesome'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

if (getEnv('VUE_APP_SENTRY_ENABLED').toLowerCase() === 'true') {
  console.log('%cAttention: This session is being logged with Sentry.io', 'color:red; font-size:1.5rem; font-weight:bold;')
  console.log('%cLogging is used for support, debugging and user experience purposes only.', 'color:red; font-weight:bold;')
  console.log(`%cSentry.io Release Tag: ${getEnv('VUE_APP_KOMPOZITION_RELEASE')}`, 'color:orange;')
  console.log('%cPlease note that Authorization tokens, passwords and cookies are not logged.', 'color:orange;')
  console.log('%cIf you have any concerns, please discuss with your system administrator.', 'color:orange;')

  Sentry.init({
    Vue,
    dsn: getEnv('VUE_APP_SENTRY_DSN'),
    environment: window.location.host,
    release: getEnv('VUE_APP_KOMPOZITION_RELEASE'),
    logErrors: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'komp.localhost', /^\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

Vue.use(VueFormWizard)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueHotkey)

// Logging
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.VUE_APP_LOGGING_LEVEL,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
})

Vue.mixin(userMixin)

Vue.mixin({
  methods: {
    getSpecificationById(specId) {
      return this.$store.state.specifications.specifications.find(({ id }) => id === specId)
    },
    isEmpty(e) { return _.isEmpty(e) },
    validateEmail(string) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(string) },
    notesModalUpdate(noteId) {
      store.dispatch('notes/setGlobalNoteId', noteId).then(() => { this.$bvModal.show('update-note-modal') })
    },
    notesModalDelete(noteId) {
      store.dispatch('notes/setGlobalNoteId', noteId).then(() => { this.$bvModal.show('delete-note-modal') })
    },
    issueModalRaise(targetId, targetLbl, updateFn) {
      store.dispatch('issues/setGlobalTarget', { id: targetId, label: targetLbl }).then(() => {
        store.dispatch('issues/setUpdateFn', updateFn).then(() => this.$bvModal.show('raise-issue-modal'))
      })
    },
    issueModalView(issueId, updateFn) {
      store.dispatch('issues/selectIssue', issueId).then(() => {
        store.dispatch('issues/setUpdateFn', updateFn).then(() => this.$bvModal.show('view-issue-modal'))
      })
    },
  },
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// import icons
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// load environment variables from .env
require('dotenv').config()

Vue.config.productionTip = false

Vue.$keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  })
  .then(async authenticated => {
    new Vue({
      router,
      store,
      mounted() {
        if (authenticated) {
          userMixin.methods.getAllUsers.call(this)
        }
      },
      render: h => h(App),
    }).$mount('#app')

    window.onfocus = () => {
      updateToken()
    }
  })
Vue.$keycloak.onAuthRefreshError = () => console.error('onAuthRefreshError')
Vue.$keycloak.onTokenExpired = async () => {
  console.log('Token has expired')
}
