import Vue from 'vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  behaviourTrees: [],
  selectedBehaviourTreeObj: {},
  selectedBehaviourTreeETag: '',
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_BEHAVIOUR_TREE_LIST: (state, data) => { state.behaviourTrees = data },
    SET_BEHAVIOUR_TREE_METADATA: (state, data) => {
      const map = new Map()
      state.behaviourTrees.forEach(item => map.set(item.id, item))
      data.forEach(item => map.set(item.id, { ...map.get(item.id), ...item }))
      state.behaviourTrees = Array.from(map.values())
    },
    SET_SELECTED_BEHAVIOUR_TREE: (state, data) => { state.selectedBehaviourTreeObj = data },
    SET_SELECTED_BEHAVIOUR_TREE_ETAG: (state, data) => { state.selectedBehaviourTreeETag = data },
    ADD_BEHAVIOUR_TREE: (state, data) => {
      const newBT = {
        ...data,
        metadata: {
          default: false,
          requirements: 0,
        },
      }
      state.behaviourTrees = [newBT, ...state.behaviourTrees]
    },
    UPDATE_BEHAVIOUR_TREE: (state, data) => {
      const index = state.behaviourTrees.findIndex(s => s.id === data.id)
      const updated = { ...state.behaviourTrees[index], ...data }
      Object.assign(state.behaviourTrees[index], updated)
    },
    DELETE_BEHAVIOUR_TREE: (state, data) => {
      const index = state.behaviourTrees.findIndex(s => s.id === data.id)
      if (index !== -1) {
        state.behaviourTrees.splice(index, 1)
      }
    },
    CLEAR_SELECTED_BEHAVIOUR_TREE_DATA: state => {
      state.selectedBehaviourTreeObj = null
      state.selectedBehaviourTreeETag = ''
    },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    getAllBehaviourTrees: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .get('v1/legacy/behaviourTrees', config)
        .then(({ data }) => commit('SET_BEHAVIOUR_TREE_LIST', data))
        .catch(e => console.error(e))
    },
    getBehaviourTreesListMetadata: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .get('v1/legacy/behaviourTrees/metadata', config)
        .then(({ data }) => { commit('SET_BEHAVIOUR_TREE_METADATA', data) })
        .catch(e => console.error(e))
    },
    getBehaviourTree: ({ commit }, btId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .get(`v1/legacy/behaviourTrees/${btId}`, config)
        .then(response => {
          commit('SET_SELECTED_BEHAVIOUR_TREE', response.data)
          // commit('SET_SELECTED_BEHAVIOUR_TREE_ETAG', response.headers.etag)
        })
        .catch(e => console.error(e))
    },
    createBehaviourTree: ({ commit, dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .post('v1/legacy/behaviourTrees', payload, config)
        .then(response => {
          if (response.status === 201) {
            commit('ADD_BEHAVIOUR_TREE', response.data)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Behaviour Tree created',
                text: response.data.name,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            dispatch('getBehaviourTreesListMetadata')
            return response
          }
          return undefined
        })
        .catch(e => console.error(e))
    },
    copyBehaviourTree: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .post(`v1/legacy/behaviourTrees/${state.selectedBehaviourTreeObj.id}/copy`, payload, config)
        .then(response => {
          if (response.status === 201) {
            commit('ADD_BEHAVIOUR_TREE', response.data)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Behaviour Tree created',
                text: response.data.name,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            return response
          }
          return undefined
        })
        .catch(e => console.error(e))
    },
    updateBehaviourTree: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: {
          'Model-Id': modelId,
          // 'If-Match': state.selectedBehaviourTreeETag,
        },
      }
      return coreService
        .patch(`v1/legacy/behaviourTrees/${state.selectedBehaviourTreeObj.id}`, payload, config)
        .then(({ data }) => {
          if (data) {
            commit('UPDATE_BEHAVIOUR_TREE', data)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Behaviour Tree updated',
                text: data.name,
                icon: 'Edit2Icon',
                variant: 'success',
              },
            })
            commit('CLEAR_SELECTED_BEHAVIOUR_TREE_DATA')
            return data
          }
          return undefined
        })
        .catch(e => console.error(e))
    },
    deleteBehaviourTree: ({ state, commit }) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: {
          'Model-Id': modelId,
          // 'If-Match': state.selectedBehaviourTreeETag,
        },
      }
      return coreService
        .delete(`v1/legacy/behaviourTrees/${state.selectedBehaviourTreeObj.id}`, config)
        .then(response => {
          if (response.status === 204) {
            commit('DELETE_BEHAVIOUR_TREE', state.selectedBehaviourTreeObj)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Behaviour Tree deleted',
                text: state.selectedBehaviourTreeObj.name,
                icon: 'Trash2Icon',
                variant: 'info',
              },
            })
            commit('CLEAR_SELECTED_BEHAVIOUR_TREE_DATA')
            return true
          }
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Behaviour Tree',
              text: state.selectedBehaviourTreeObj.name,
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
          return false
        })
        .catch(e => console.error(e))
    },
    clearSelectedBehaviourTree: ({ commit }) => { commit('CLEAR_SELECTED_BEHAVIOUR_TREE_DATA') },
    clearBehaviourTrees: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
