export class LegacyApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List All
     * Get all Behaviour Trees in Model
 * :return: JSON object representation of all found Behaviour Trees
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listAll(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create
     * Create a Behaviour Tree
 * :return: JSON object representation of the created Behaviour Tree
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    create(requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviourTrees',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List All Metadata
     * Get all metadata for Behaviour Trees in Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listAllMetadata(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees/metadata',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    get(btId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    delete(btId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * @param btId
     * @returns any Successful Response
     * @throws ApiError
     */
    update(btId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy
     * Copy the Behaviour Tree with id {bt_id}
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    copy(btId, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviourTrees/{bt_id}/copy',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Behaviour Trees With Context
     * Get all Behaviour Trees in model with full context
 * :return: JSON object representation of all found Behaviour Tree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    fetchBehaviourTreesWithContext(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Tree With Context
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourTreeWithContext(btId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bt/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Function Paths
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getFunctionPaths(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_function_paths/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Unallocated Nodes
     * Get the list of all nodes in BT with no requirement allocation
 * :param behaviour_tree_id: Unique ID for a Behaviour Tree to search within
 * - ARG specification: Unique ID for a Specification to search against
 * :return: List of Behaviour Node IDs that have no Requirement allocation
     * @param behaviourTreeId
     * @param specificationId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUnallocatedNodes(behaviourTreeId, specificationId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_unallocated_nodes/{behaviour_tree_id}',
            path: {
                'behaviour_tree_id': behaviourTreeId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'specification_id': specificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reorder Siblings
     * @param bnId
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    reorderSiblings(bnId, requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/reorder_siblings/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviours For Requirement
     * @param bt
     * @param req
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehavioursForRequirement(bt, req) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviours_for_requirement/{bt}/{req}',
            path: {
                'bt': bt,
                'req': req,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Context
     * @param bt
     * @param bn
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourContext(bt, bn, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviour_context/{bt}/{bn}',
            path: {
                'bt': bt,
                'bn': bn,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Closed And Withdrawn Issues
     * @param bn
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourClosedAndWithdrawnIssues(bn) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviour_closed_and_withdrawn_issues/{bn}',
            path: {
                'bn': bn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Display
     * @param bn
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourDisplay(bn, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{bn}/display',
            path: {
                'bn': bn,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Common Requirements
     * Returns all the requirements that are shared with the behaviour nodes provided
     * @param selectedNodes
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCommonRequirements(selectedNodes, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_common_requirements',
            headers: {
                'model-id': modelId,
            },
            query: {
                'selected_nodes': selectedNodes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unassociate Requirements
     * Unassociates the requirement from selected behaviour nodes
 * Used by bulk sidebar
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    unassociateRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/unassociate_requirements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Requirements
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeRequirements(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/requirements',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Node Requirements
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateNodeRequirements(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/requirements',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Issues
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeIssues(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/issues',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Issues
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeIssues(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/issues',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Objectives
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeObjectives(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/objectives',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Objectives
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeObjectives(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/objectives',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Tests
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeTests(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/tests',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Tests
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeTests(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/tests',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Enablers
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeEnablers(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/enablers',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Enablers
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeEnablers(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/enablers',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Beneficiaries
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeBeneficiaries(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/beneficiaries',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Beneficiaries
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeBeneficiaries(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/beneficiaries',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Standards
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeStandards(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/standards',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Standards
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeStandards(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/standards',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Properties
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeProperties(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/properties',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Properties
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeProperties(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/properties',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Notes
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeNotes(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/notes',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Node Notes
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    nodeNotes(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/notes',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Node Note
     * @param nodeId
     * @param noteId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteNodeNote(nodeId, noteId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/behaviour/nodes/{node_id}/note/{note_id}',
            path: {
                'node_id': nodeId,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Functions
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeFunctions(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/functions',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Functions
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeFunctions(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/functions',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Node Timing
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    nodeTiming(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/timing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Node Timing
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateNodeTiming(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/update_node_timing/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Node Timing
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteNodeTiming(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/delete_node_timing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Functions
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateFunctions(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_functions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Requirements
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_requirements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Issues
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateIssues(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_issues',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Objectives
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateObjectives(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_objectives',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Tests
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateTests(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_tests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Notes
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateNotes(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Enablers
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateEnablers(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_enablers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Properties
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateProperties(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_properties',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Behaviour Text
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    parseBehaviourText(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/parse_behaviour_text',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Behaviour Text2
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    parseBehaviourText2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/parse_behaviour_text2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Bn
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBn(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_bn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Behaviour Node
     * update behavour node with <<bn_id>> with json properties
 * json: {
     * "component":  BN subject component (name or id)
     * "type":  Event | State | ...
     * "objects" [{"preposition": ..., "object": ...}]
     * "io_resource": Resource component (name or id) - used in Inputs and Outputs only
     * ...other fields to augment BN information...
     * }
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBehaviourNode(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/behaviour/update_behaviour/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Behaviour Properties
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBehaviourProperties(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/update_behaviour_properties/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Bn
     * Delete bn and optionally children
     * json...{bn: <<bn_id>>, bt: <<bt_id>>, delete_children: True|[False*]
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBn(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/delete_bn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bn Descendents
     * @param bn
     * @param bt
     * @returns any Successful Response
     * @throws ApiError
     */
    getBnDescendents(bn, bt) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bn_descendents/{bn}/{bt}',
            path: {
                'bn': bn,
                'bt': bt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Bn From Requirement
     * Removes the ALLOCATED_TO rel between a BehaviorNode and Requirement
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateBnFromRequirement(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/disassociate_bn_requirement/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bn Integrations
     * @param bn
     * @param bt
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBnIntegrations(bn, bt, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bn_integrations/{bn}/{bt}',
            path: {
                'bn': bn,
                'bt': bt,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Bn Relationship
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBnRelationship(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_bn_relationship',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Sao Behaviour
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createSaoBehaviour(requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_sao_behaviour',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Integrate
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    integrate(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/integrate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    move(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/move',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Node
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyNode(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/copy_node',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Bt
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyBt(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/copy_bt',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Instantiate Interface
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    instantiateInterface(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/instantiate_interface',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Tests
     * @param bt
     * @returns any Successful Response
     * @throws ApiError
     */
    generateTests(bt) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/generate_tests/{bt}',
            path: {
                'bt': bt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Testedness
     * @param bt
     * @param model
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestedness(bt, model, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_testedness/{bt}',
            path: {
                'bt': bt,
            },
            query: {
                'model': model,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Enablement
     * @param bt
     * @param model
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getEnablement(bt, model, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/{bt}/enablement',
            path: {
                'bt': bt,
            },
            query: {
                'model': model,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refine Behaviour
     * Refine a node (source_id) to BT (new BT called ref_name, if ref_id is None,
     * otherwise to root of BT with id = ref_id)
     * For existing BT:
     * Refines to root if function_name is blank
     * Refines to a new child abstract function if function_id is blank
     * Else refines to existing abstract function of function_id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    refineBehaviour(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/refine_behaviour',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refine New
     * Refine a node to a new BT
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    refineNew(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/refine_new',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Frs
     * @param bt
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    generateFrs(bt, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/generate_frs/{bt}',
            path: {
                'bt': bt,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Segments
     * @param bn
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSegments(bn, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_segments/{bn}',
            path: {
                'bn': bn,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Tc From Segment
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateTcFromSegment(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/generate_tc_from_segment',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bt Export
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    btExport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bt Import
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    btImport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/import',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approval Request
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalRequest(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/approvals',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Canvas
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    canvas(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Assumptions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllAssumptions(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/assumptions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Assumptions
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    postAssumptions(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/assumptions',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Assumption
     * @param assumptionId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssumption(assumptionId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Assumption
     * @param assumptionId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateAssumption(assumptionId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Assumption
     * @param assumptionId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteAssumption(assumptionId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Benefits
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllBenefits(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/benefits',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Benefit
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBenefit(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/benefits',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Benefit
     * @param benefitId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBenefit(benefitId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Benefit
     * @param benefitId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBenefit(benefitId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Benefit
     * @param benefitId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBenefit(benefitId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Constraints
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllConstraints(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/constraints',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Constraint
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createConstraint(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/constraints',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Constraint
     * @param constraintId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getConstraint(constraintId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Constraint
     * @param constraintId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateConstraint(constraintId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Constraint
     * @param constraintId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteConstraint(constraintId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Dependencies
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllDependencies(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/dependencies',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Dependency
     * @param requestBody
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createDependency(requestBody, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/dependencies',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency(dependencyId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency1(dependencyId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency2(dependencyId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Kpis
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getKpis(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/kpis',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Kpis
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createKpis(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/kpis',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Kpi
     * @param kpiId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateKpi(kpiId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/kpis/{kpi_id}',
            path: {
                'kpi_id': kpiId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Kpi
     * @param kpiId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteKpi(kpiId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/kpis/{kpi_id}',
            path: {
                'kpi_id': kpiId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Objectives
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getObjectives(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Objective
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createObjective(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    objective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateObjective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteObjective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objective Benefits
     * @param objId
     * @returns any Successful Response
     * @throws ApiError
     */
    objectiveBenefits(objId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objective/{obj_id}/benefits',
            path: {
                'obj_id': objId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Objective Benefits
     * @param objId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateObjectiveBenefits(objId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/objective/{obj_id}/benefits',
            path: {
                'obj_id': objId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Stakeholders
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllStakeholders(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/stakeholders',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Stakeholder
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createStakeholder(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/stakeholders',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Stakeholder
     * @param stakeholderId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getStakeholder(stakeholderId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Stakeholder
     * @param stakeholderId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateStakeholder(stakeholderId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Stakeholder
     * @param stakeholderId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteStakeholder(stakeholderId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capabilities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    capabilities(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/capabilities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Capabilities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createCapabilities(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/capabilities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    capability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateCapability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteCapability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comp Tree Vue
     * @param model
     * @param partial
     * @returns any Successful Response
     * @throws ApiError
     */
    compTreeVue(model = '', partial = 'true') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_tree',
            query: {
                'model': model,
                'partial': partial,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comp Tree Vue At Root
     * @param root
     * @param model
     * @param partial
     * @returns any Successful Response
     * @throws ApiError
     */
    compTreeVueAtRoot(root, model = '', partial = 'true') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_tree/{root}',
            path: {
                'root': root,
            },
            query: {
                'model': model,
                'partial': partial,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Next Level Vue
     * @param root
     * @returns any Successful Response
     * @throws ApiError
     */
    nextLevelVue(root) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/next_level/{root}',
            path: {
                'root': root,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Entity
     * @param entityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    entity(entityId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/entity/{entity_id}',
            path: {
                'entity_id': entityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComposition(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component
     * create component from json (dict)
     * NOTE (both optional):
     * labels sent as a list in the json request {.... labels: ['Label',...], }
     * parent sent in json request {..., parent: 'Parent', parent_rel: 'aggregation | inheritance', ... }
     * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponent() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/composition',
        });
    }
    /**
     * Get Components Simple
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentsSimple(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_components_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Functions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getFunctions(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/functions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Enablers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getEnablers(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/enablers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component
     * @param cpt
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponent(cpt, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition/{cpt}',
            path: {
                'cpt': cpt,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Subtree
     * @param root
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionSubtree(root, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_composition_subtree/{root}',
            path: {
                'root': root,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Subtree
     * @param model
     * @param root
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionSubtree1(model, root) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_composition_subtree',
            query: {
                'model': model,
                'root': root,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Weighted Req
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionWeightedReq(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_weighted_req',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Weighted Iss
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionWeightedIss(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_weighted_iss',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component By Id
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentById(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_by_id/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Components By Label
     * @param label
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentsByLabel(label, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_components_by_label/{label}',
            path: {
                'label': label,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Unallocated Components
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getUnallocatedComponents(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_unallocated_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matching Components
     * @param name
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getMatchingComponents(name, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_matching_components/{name}',
            path: {
                'name': name,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matching Components For List
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMatchingComponentsForList(model, requestBody) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_matching_components',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Fn Decomp
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getFnDecomp(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_fn_decomp',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Behaviour
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentBehaviour(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_behaviour/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Requirements
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentRequirements(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_requirements/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Issues
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentIssues(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_issues/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Component Issues
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponentIssues(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_all_component_issues',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Attributes
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentAttributes(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_attributes/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Constraints
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentConstraints(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_constraints/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Aliases
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentAliases(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_aliases/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Interfaces
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInterfaces(id, modelId = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_interfaces/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'modelId': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Instances
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInstances(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_instances/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Instance
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInstance(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/instance/{_id}',
            path: {
                '_id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Instance
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteInstance(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/instance/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Instances
     * @param componentId
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsInstances(componentId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{component_id}/instances',
            path: {
                'component_id': componentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Components Instance
     * @param componentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentsInstance(componentId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{component_id}/instances',
            path: {
                'component_id': componentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Component Instances
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponentInstances(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_all_component_instances',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Instance
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInstance(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_instance/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Build Configurations
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    buildConfigurations(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/build_configurations',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Build Configuration
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBuildConfiguration(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/build_configurations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Build Configurations
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBuildConfigurations(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_build_configurations',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Build Configuration
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getBuildConfiguration(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/build_configuration/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Build Configuration
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBuildConfiguration(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/build_configuration/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Build Configuration
     * @param configurationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBuildConfiguration(configurationId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/build_configuration/{configuration_id}',
            path: {
                'configuration_id': configurationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Build Configuration From Release
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBuildConfigurationFromRelease(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_build_configuration_from_release',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Releases
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentReleases(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_releases/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Parent
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentParent(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_parent/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Sets
     * @param searchQuery
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentSets(searchQuery, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_sets',
            query: {
                'search_query': searchQuery,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Issues
     * @param componentId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsIssues(componentId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{component_id}/issues',
            path: {
                'component_id': componentId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Components Issues
     * @param componentId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentsIssues(componentId, model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{component_id}/issues',
            path: {
                'component_id': componentId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Requirements
     * @param compId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsRequirements(compId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{comp_id}/requirements',
            path: {
                'comp_id': compId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Requirements
     * @param compId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsRequirements1(compId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{comp_id}/requirements',
            path: {
                'comp_id': compId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Multiple Components
     * This is more of a special use case API endpoint that just simplified some JavaScript
     * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createMultipleComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_multiple_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Impacted Fns
     * @param id
     * @param model
     * @param newName
     * @returns any Successful Response
     * @throws ApiError
     */
    getImpactedFns(id, model, newName = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_impacted_fns/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
                'new_name': newName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Rename Impacted Fns
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    renameImpactedFns(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/rename_impacted_things',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Swap Alias Name
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    swapAliasName(id, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/swap_alias_name/{_id}',
            path: {
                '_id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Component
     * @param cptId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComponent(cptId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/composition/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Component
     * @param cptId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponent(cptId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/composition/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Update Components
     * Utility endpoint to bulk update components
     * :return:
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkUpdateComponents(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/bulk_update_components',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Component And Requirements
     * Post request with a json structured as:
     * {
         * 'components': ['a component id', ...],
         * 'requirements':  ['R_id_1', ..., 'R-id_n']
         * }
         * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateComponentAndRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/associate_requirements_with_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Component And Releases
     * Associate component with release
         * Post request with a json structured as:
         * {
             * 'component': 'a component id',
             * 'releases':  ['a release id', ...]
             * }
             * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateComponentAndReleases(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/associate_releases_with_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Component From Releases
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateComponentFromReleases(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_releases_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Comp Rels
     * Create component relations
             * Requires:
             * {
                 * 'name': <<relation name>>,
                 * 'source': <<source component id>>,
                 * 'target': <<target component id>>
                 * }
                 * Other key-value pairs handled for keys:
                 * 'source_multiplicity', 'source_label', 'target_multiplicity', 'target_label'
                 * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createCompRels(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/comp_rels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Multiple Comp Rels
     * Create component relations
                 * Requires:
                 * {'rels': [
                     * {
                         * 'name': <<relation name>>,
                         * 'source': <<source component id>>,
                         * 'target': <<target component id>>
                         * },
                         * ...
                         * ]}
                         * Other key-value pairs handled for keys:
                         * 'source_multiplicity', 'source_label', 'target_multiplicity', 'target_label'
                         * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    createMultipleCompRels() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_comp_rels',
        });
    }
    /**
     * Delete Component Relation
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponentRelation(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/delete_component_relation/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Component From Requirement
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateComponentFromRequirement(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_requirements_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate All Component Requirements
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateAllComponentRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_all_requirements_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Merge Components
     * Merge <from> component with <to> component
                         * Expecting json to be posted as { 'from': component, 'to': component }
                         * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    mergeComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/merge_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Validate Component Name
     * @returns any Successful Response
     * @throws ApiError
     */
    validateComponentName() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/validate_component_name',
        });
    }
    /**
     * Copy Components
     * Copy <cpt> to a new component with <name> under <parent> with <parent_rel_type>
                         * optionally copying <requirements> and <issues>
                         * Expecting json to be posted as
                         * {
                             * 'cpt': 'a component id',
                             * 'name': 'the new name of the copy',
                             * 'parent': 'the parent under which to put the copy - PROBLEM_SPACE by default',
                             * 'parent_rel_type': 'aggregation or inheritance',
                             * 'copy_reqs': 'True or False - False by default',
                             * 'copy_issues': 'True or False - False by default'
                             * }
                             * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    copyComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/copy_component',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Component
     * Move component with <id> under parent with <parent_id> with <rel_type> of ["part_of", "type_of"]
                             * All parameters passed as posted JSON
                             * {
                                 * "id": ...the id of the moving component,
                                 * "parent_id": ...the parent id to move it under (defaults to PROBLEM_SPACE),
                                 * "parent_rel_type": ...the type of composition ["aggregation"|"inheritance"] (defaults to "aggregation")
                                 * }
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    moveComponent(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/move_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Bulk Component
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    moveBulkComponent(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/move_bulk_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Parent Rel
     * Change the parent relationship between the component with id=<id> from TYPE_OF to PART_OF and vice versa
                                 * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    changeParentRel() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/change_parent_rel',
        });
    }
    /**
     * Add Attribute
     * Add <attribute> characterised by post request json to cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addAttribute(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_attribute/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interfaces
     * Get all interfaces
                                 * :return: JSON object containing all found Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfaces(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Interface
     * Add interface to model (passed a dictionary of properties defining the interface)
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    addInterface(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interface
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterface(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface
     * Update <interface> details where interface._id = _id
                                 * :param _id:
                                 * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterface(id) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Interface
     * Delete interface with id
                                 * :param _id: Unique ID of the Interface
                                 * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteInterface(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Reqs
     * Update <interface> requirements where interface.id = _id
                                 * :param _id:
                                 * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceReqs(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_interface_reqs/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Attribute
     * Delete <attribute> from cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    delAttribute() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_attribute',
        });
    }
    /**
     * Update Attribute
     * Update <attribute> details where attribute.id = attr_id
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param attr_id:
                                 * :return:
     * @param attrId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateAttribute(attrId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_attribute/{attr_id}',
            path: {
                'attr_id': attrId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Property
     * Add <property> characterised by post request json to cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addProperty(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_property/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Property
     * Delete <property> from cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    delProperty() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_property',
        });
    }
    /**
     * Update Property
     * Update <property> details where property.id = prop_id
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param prop_id:
                                 * :return:
     * @param propId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateProperty(propId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_property/{prop_id}',
            path: {
                'prop_id': propId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Constraint
     * Add <constraint> characterised by post request json to cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addConstraint(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_constraint/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Constraint To
     * Cop constraint identified by <constraint_id> to cpt with id=<cpt_id>
                                 * pre: json request must have a 'constraint_id' key with a non null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    copyConstraintTo(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/copy_constraint_to/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Constraint
     * Get constraint by ID
                                 * :param _id:
                                 * :return:
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getConstraint1(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_constraint/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Constraints
     * Add multiple <constraint>s from parsing
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    addConstraints(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_constraints',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Constraint
     * Delete <constraint> from cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    delConstraint(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_constraint',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Constraint
     * Update <constraint> details where constraint.id = constraint_id
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param constraint_id:
                                 * :return:
     * @param constraintId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateConstraint1(constraintId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_constraint/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Compliance Requirement
     * Add <ComplianceRequirement> characterised by post request json
                                 * pre: json request must have a 'reference' key with a non-null value
                                 * :return:
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createComplianceRequirement(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/compliance_requirement',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Constraint From Property
     * Create a Quality Attribute Requirement (constraint)
                                 * from property identified by <property_id> to cpt with id=<cpt_id>
                                 * pre: json request must have a 'property_id' key with a non-null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    createConstraintFromProperty(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_constraint_from_property/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Compliance Requirement
     * Get ComplianceRequirement by ID
                                 * :param _id:
                                 * :return:
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComplianceRequirement(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_compliance_requirement/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Compliance Requirement
     * Update <ComplianceRequirement> details where ComplianceRequirement.id = constraint_id
                                 * pre: json request must have a 'reference' key with a non null value
                                 * :param compliance_req_id:
                                 * :return:
     * @param complianceReqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComplianceRequirement(complianceReqId, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/compliance_requirement/{compliance_req_id}',
            path: {
                'compliance_req_id': complianceReqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Compliance Requirement
     * Delete <ComplianceRequirement> node based on it's ID and Model ID.
                                 * :return: Number of deleted nodes
     * @param complianceReqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComplianceRequirement(complianceReqId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/compliance_requirement/{compliance_req_id}',
            path: {
                'compliance_req_id': complianceReqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Alias
     * Add <alias> characterised by post request json to cpt with id=<cpt_id>
                                 * pre: json request must have a 'name' key with a non null value
                                 * :param cpt_id:
                                 * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addAlias(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_alias/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Alias
     * Delete alias from component by <id>
                                 * :param _id:
                                 * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteAlias(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/alias/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Context
     * @param cpt
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentContext(cpt, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_context/{cpt}',
            path: {
                'cpt': cpt,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Requirements From Fn
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromFn() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_fn',
        });
    }
    /**
     * Generate Requirements From If
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromIf() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_if',
        });
    }
    /**
     * Generate Requirements From Constraint
     * Generate Requirements from Constraints
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromConstraint(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_constraint',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Requirements From Compliance
     * Generate Requirements from Constraints
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromCompliance(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_compliance',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Constraint Requirements From Component
     * Generate Requirements from Constraints
     * @returns any Successful Response
     * @throws ApiError
     */
    generateConstraintRequirementsFromComponent() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_constraint_requirements_from_component',
        });
    }
    /**
     * Allocate Function
     * @returns any Successful Response
     * @throws ApiError
     */
    allocateFunction() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/allocate_function',
        });
    }
    /**
     * Constrainfunction
     * @returns any Successful Response
     * @throws ApiError
     */
    constrainFunction() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/constrain_function',
        });
    }
    /**
     * Import Model
     * @returns any Successful Response
     * @throws ApiError
     */
    importModel() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_subtree',
        });
    }
    /**
     * Export Uml
     * @returns any Successful Response
     * @throws ApiError
     */
    exportUml() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_uml',
        });
    }
    /**
     * Export Trl
     * @returns any Successful Response
     * @throws ApiError
     */
    exportTrl() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_trl',
        });
    }
    /**
     * Import Static Uml
     * @returns any Successful Response
     * @throws ApiError
     */
    importStaticUml() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_static_uml',
        });
    }
    /**
     * Export Performers Report
     * @returns any Successful Response
     * @throws ApiError
     */
    exportPerformersReport() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_performers_report',
        });
    }
    /**
     * Get Report Data
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getReportData(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/performers_report',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Subtree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    exportSubtree(modelId = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_subtree',
            query: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Subtree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    importSubtree(modelId = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_subtree_json',
            query: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Convert Entity To Attribute
     * Convert a Component (Entity) to an Attribute of another Component
     * @returns any Successful Response
     * @throws ApiError
     */
    convertEntityToAttribute() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/convert_entity_to_attribute',
        });
    }
    /**
     * Get Compliance For Spec
     * @param cptId
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComplianceForSpec(cptId, specId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/compliance/{cpt_id}/{spec_id}',
            path: {
                'cpt_id': cptId,
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Record Compliance For Req
     * Record a Compliance Record for Component and Requirement
     * @returns any Successful Response
     * @throws ApiError
     */
    recordComplianceForReq() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/record_compliance_for_req',
        });
    }
    /**
     * Get Simple Requirements For Model
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSimpleRequirementsForModel(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/get_requirements_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements
     * Get all requirements for the given model id
                                 * :param kwargs:
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirements(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirement(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Context
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementContext(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}/context',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement
     * Get all requirement by given id for the given model id
                                 * :param req_id: ID of the Requirement to query
                                 * :return:
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    patchRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Restore Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    restoreRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/{req_id}/restore',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements Table
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsTable(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/table_data',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Table Layout
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTableLayout(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/table_layout',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Requirements
     * Get all requirements for the given model id
                                 * :param spec:
                                 * :param kwargs:
                                 * :return:
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationRequirements(spec, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/for_spec/{spec}',
            path: {
                'spec': spec,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Docx Templates
     * Get a list of all .docx template files in the 'instance/uploads' folder
                                 * :return: List[str]
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDocxTemplates(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/docx_templates',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Requirements Docx
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportRequirementsDocx(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Trace Report
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportTraceReport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/trace_report',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Distinct Requirement Attributes For Model Or Spec
     * @param model
     * @param specification
     * @param forUpload
     * @returns any Successful Response
     * @throws ApiError
     */
    listDistinctRequirementAttributesForModelOrSpec(model, specification, forUpload = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes',
            query: {
                'model': model,
                'specification': specification,
                'for_upload': forUpload,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Attributes For Spec
     * @param spec
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementAttributesForSpec(spec) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes/for_spec/{spec}',
            path: {
                'spec': spec,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Specification
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementSpecification(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/specifications',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Options
     * @param model
     * @param spec
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementOptions(model, spec) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/options',
            query: {
                'model': model,
                'spec': spec,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Req Trace
     * @param reqId
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getReqTrace(reqId, specId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/trace/{req_id}/{spec_id}',
            path: {
                'req_id': reqId,
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Ids
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementIds(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/ids',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement Trace
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirementTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/trace',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Backwards Trace
     * JSON input expectations
                                 * trace: [....],
                                 * id: <<id of the requirement tracing backward from>>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createBackwardsTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/create_backward_traces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Forward Trace
     * JSON input expectations
                                 * sources: [....],
                                 * target: <<id of the requirement tracing forward from>>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createForwardTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/create_forward_traces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Requirement2
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    parseRequirement2(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}/parse2',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Parse Results2
     * Use parsing results to create components, relations, behaviours and issues
                                 * :return:
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateParseResults2(reqId, model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/{req_id}/associate_parse2',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Req Fns
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReqFns(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_req_fns',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Entities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateEntities(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_entities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Performers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associatePerformers(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_performers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Enablers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateEnablers(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_enablers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Beneficiaries
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateBeneficiaries(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_beneficiaries',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate References
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReferences(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_references',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Releases
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReleases(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_releases',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Functions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateFunctions(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_functions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateInterfaces(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Resources
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateResources(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_resources',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Objectives
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateObjectives(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Parse Results
     * TODO: Should be able to delete this
                                 * Use parsing results to create components, relations, behaviours and issues
                                 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateParseResults(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_parse',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Additional
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    additional(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements Report
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementsReport(spec, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/requirements_report/{spec}',
            path: {
                'spec': spec,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements Report
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementsReport1(spec, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/requirements_report',
            query: {
                'spec': spec,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement
     * :param req_id: ID of the Requirement
                                 * :return: JSON object of Requirement data
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement1(reqId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Vcrm
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementVcrm(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/vcrm',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Trace
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementTrace(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Analyse Impact
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    analyseImpact(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/impact_analysis',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Issues
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementIssues(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/issues',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Tests
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementTests(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/tests',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement History
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementHistory(reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirement/{req_id}/history',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comments
     * @param label
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    comments(label, id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/comments',
            query: {
                'label': label,
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comments
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    comments1(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/comments',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComment(id, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComment(id, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComment(id, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interfaces Simple
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfacesSimple(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/get_interfaces_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Interfaces
     * Get all interfaces with queries, and less wait
     * @param model
     * @param q
     * @param status
     * @param type
     * @param irl
     * @param sortBy
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllInterfaces(model, q = '', status = '', type = '', irl = '', sortBy = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces',
            query: {
                'model': model,
                'q': q,
                'status': status,
                'type': type,
                'irl': irl,
                'sortBy': sortBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Interface
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterface() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces',
        });
    }
    /**
     * Create Interface
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterface1() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/create',
        });
    }
    /**
     * Interface
     * @param interfaceId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    interface(interfaceId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/{interface_id}',
            path: {
                'interface_id': interfaceId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Interface
     * @param interfaceId
     * @returns any Successful Response
     * @throws ApiError
     */
    interface1(interfaceId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/interfaces/{interface_id}',
            path: {
                'interface_id': interfaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    update1(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Reqs
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceReqs1(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_reqs/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Fns
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceFns(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_fns/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Standards
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceStandards(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_standards/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interface Releases
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfaceReleases(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/get_interface_releases/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Interface And Releases
     * Associate interface with release
                                 * Post request with a json structured as:
                                 * {
                                     * 'interface': 'a interface id',
                                     * 'releases':  ['a release id', ...]
                                     * }
                                     * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    associateInterfaceAndReleases() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/associate_releases_with_interface',
        });
    }
    /**
     * Disassociate Interface From Releases
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateInterfaceFromReleases() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/disassociate_releases_from_interface',
        });
    }
    /**
     * Export If Report
     * @returns any Successful Response
     * @throws ApiError
     */
    exportIfReport() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/report',
        });
    }
    /**
     * Create Test
     * Create a new TestCase
                                     * Requires JSON in the request
                                     * :return: 201 status - created
     * @returns any Successful Response
     * @throws ApiError
     */
    createTest() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/create_test',
        });
    }
    /**
     * Test Details
     * GET/UPDATE/DELETE test (and associated details) with the given test_id
                                     * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    testDetails(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/details/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Page Number Finder
     * Identify the page number a test case would be on given a per page limit
                                     * :return: JSON object of TestCase id, page number
     * @param testId
     * @param modelId
     * @param perPage
     * @returns any Successful Response
     * @throws ApiError
     */
    pageNumberFinder(testId, modelId = '', perPage = 100) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/page_number_identifier/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'modelId': modelId,
                'perPage': perPage,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get External Test
     * GET/UPDATE/DELETE test with the foreign (ref_id) of test_id
                                     * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put External Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete External Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTest(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Requirement
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithRequirement() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_requirement',
        });
    }
    /**
     * Associate Test With Requirements
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithRequirements(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Components
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithComponents(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Instances
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithInstances(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_instances',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Configs
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithConfigs(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_configs',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Components With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedComponentsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_components',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Instances With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedInstancesWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_instances',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Requirements With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedRequirementsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_requirements',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Configurations With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedConfigurationsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_configurations',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Behaviours With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedBehavioursWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_behaviours',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Test Associations
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTestAssociations() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/delete_associations',
        });
    }
    /**
     * Delete Test Requirement Associations
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTestRequirementAssociations() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/delete_test_to_requirement_association',
        });
    }
    /**
     * Get External Tests By Requirement And Model
     * This endpoint was created for external use
                                     * Get all tests using the requirement ID and model ID
                                     * Important note:
                                     * What the external people understand as the requirement ID is = display_id in our database
                                     * :return: JSON object of tests
     * @param requirement
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getExternalTestsByRequirementAndModel(requirement, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_tests_by_requirement_and_model',
            query: {
                'requirement': requirement,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Log Test Execution
     * Record a Test Execution against a Test Case
                                     * :payload {"build_id", "datetime", "result"}
                                     * :return: JSON object of the Test Execution
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    logTestExecution(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/log_test_execution',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Executions
     * Get a list of all TestExecutions for the given TestCase
                                     * :return: JSON array of all Test Executions
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestExecutions(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_executions',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Test Executions
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putTestExecutions(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/get_executions',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Procedures
     * Get a list of all TestProcedures and their details
                                     * :return: JSON array of all Test Procedures
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestProcedures(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/test_procedures',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Procedure
     * Get a TestProcedures and its details
                                     * :return: JSON array of Test Procedure
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestProcedure(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/test_procedures/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test In Behavior Tree
     * Get a list of all TestExecutions for the given Behavior Tree
                                     * :return: JSON array of all Test Executions
     * @param behaviorTreeId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestInBehaviorTree(behaviorTreeId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_tests_in_behavior_tree',
            query: {
                'behavior_tree_id': behaviorTreeId,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Conditions
     * Get a list of all testable conditions for all element attributes
                                     * :return: JSON array of all Test Executions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestConditions(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_test_conditions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Test Cases
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    importTestCases(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/import',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Tests
     * Get all tests for the given model id
                                     * :return: JSON array of TestCases
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    tests(model, simple = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Tests
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    postTests(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * GET/UPDATE/DELETE test with the given test_id
                                     * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test1(testId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test2(testId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Criteria
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCriteria(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_criteria',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Options
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getOptions(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_options',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Options And Criteria
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getOptionsAndCriteria(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_options_and_criteria',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Criteria Comparison Matrix
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createCriteriaComparisonMatrix(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/weights',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Ts Criteria Slider
     * @param id
     * @param csId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTsCriteriaSlider(id, csId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/delete_criteria_slider/{cs_id}',
            path: {
                'id': id,
                'cs_id': csId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trades
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    trades(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Trade
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createTrade(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/options',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/options',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Options
     * @param id
     * @param optionId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyOptions(id, optionId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/options/{option_id}',
            path: {
                'id': id,
                'option_id': optionId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    postTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    patchTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Criteria
     * @param id
     * @param criteriaId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyCriteria(id, criteriaId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/criteria/{criteria_id}',
            path: {
                'id': id,
                'criteria_id': criteriaId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Score
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudyScore(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/score',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Trade Study Score
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    patchTradeStudyScore(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/score',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Score
     * @param id
     * @param scoreId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyScore(id, scoreId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/score/{score_id}',
            path: {
                'id': id,
                'score_id': scoreId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Slider Value
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudySliderValue(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/slider',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Slider Value
     * @param id
     * @param sliderId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudySliderValue(id, sliderId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/slider/{slider_id}',
            path: {
                'id': id,
                'slider_id': sliderId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Duplicated Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    duplicatedTradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/duplicate',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Lock Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    lockTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/lock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unlock Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    unlockTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/unlock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Trade Study Lock
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    checkTradeStudyLock(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/check_lock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Releases
     * @param model
     * @param sorting
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllReleases(model, sorting = 'end') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/releases',
            query: {
                'model': model,
                'sorting': sorting,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Release
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRelease(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/releases',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Release
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRelease(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/releases/delete/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Release
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRelease(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/releases/update/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Release
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRelease(id, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/releases/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Reviews
     * @param model
     * @param archived
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllReviews(model, archived) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/reviews',
            query: {
                'model': model,
                'archived': archived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specifications
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecifications(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Specifications
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    postSpecifications(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Options
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationOptions(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/options',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Section Headers
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationSectionHeaders(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/section_headers',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Compare Specification Snapshot
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    compareSpecificationSnapshot(snapshotId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/snapshot_compare/{_snapshot_id}',
            path: {
                '_snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Additional Attributes
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAdditionalAttributes(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/additional_attributes/{spec_id}',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Attributes
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAttributes(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/attributes/{spec_id}',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements Legacy
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsLegacy(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/requirements',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Issues
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getIssues(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/issues',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tests
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTests(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{spec_id}/tests',
            path: {
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Spec
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    copySpec(specId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{spec_id}/copy',
            path: {
                'spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Approval For Specification
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getApprovalForSpecification(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approve Approval Request
     * Approve the ApprovalRequest, and baseline the Specification
                                     * :param _spec_id: Specification ID to action upon
                                     * :return: JSON object containing the created Baseline
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approveApprovalRequest(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/approve',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approval Action
     * Reject the ApprovalRequest with reason, and TODO do something
                                     * :param _spec_id: Specification ID to action upon
                                     * :return: JSON object containing the updated ApprovalRequest
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalAction(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval/action',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Approval Action
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getApprovalAction(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/action',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Approval
     * Finalise the Specification Review, and start the ApprovalRequest
                                     * :param _spec_id: Specification ID to action upon
                                     * :return: JSON object containing the created ApprovalRequest
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    startApproval(specId, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{_spec_id}/approval/start',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approval Reviewers
     * Get all Reviewers for a ApprovalRequest
                                     * :return Return an array list of Reviewers
                                     * :rtype JSON Array
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    approvalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Approval Reviewers
     * Replace all existing Reviewers with the given list of Reviewers
                                     * :return Return an array list of Reviewers
                                     * :rtype JSON Array
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putApprovalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Approval Reviewers
     * Add or remove a Reviewer from the existing list
                                     * Required payload: { username, action(add|remove|decision) }
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    patchApprovalReviewers(specId, model = '') {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/specifications/{_spec_id}/approval/reviewers',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baselines
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselines(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/baselines',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baseline Compare
     * @param specId
     * @param baselineVersion
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselineCompare(specId, baselineVersion, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/baselines/{_baseline_version}/compare',
            path: {
                '_spec_id': specId,
                '_baseline_version': baselineVersion,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baseline Delete
     * @param specId
     * @param baselineVersion
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    baselineDelete(specId, baselineVersion, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/specifications/{_spec_id}/baselines/{_baseline_version}/delete',
            path: {
                '_spec_id': specId,
                '_baseline_version': baselineVersion,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshots(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshots',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots Compare
     * @param specId
     * @param snapshotId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshotsCompare(specId, snapshotId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}/compare',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshots Compare Requirement
     * @param specId
     * @param snapshotId
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshotsCompareRequirement(specId, snapshotId, reqId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}/compare/{_req_id}',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
                '_req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Snapshot
     * @param specId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createSnapshot(specId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot',
            path: {
                '_spec_id': specId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Snapshot
     * @param specId
     * @param snapshotId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateSnapshot(specId, snapshotId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}/snapshot/{_snapshot_id}',
            path: {
                '_spec_id': specId,
                '_snapshot_id': snapshotId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Snapshots Simple
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    snapshotsSimple(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}/snapshots_simple',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Coverage Table
     * Create a Requirement with a table reporting coverage from <child> back to <parent>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    generateCoverageTable(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/generate_coverage_table',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Interface Table Requirement
     * Create a Requirement with a table reporting the selected Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    generateInterfaceTableRequirement(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/specifications/generate_interface_table',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecification(specId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/specifications/{_spec_id}',
            path: {
                '_spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Specification
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateSpecification(specId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/specifications/{_spec_id}',
            path: {
                '_spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set As Default Specification
     * @param specId
     * @returns any Successful Response
     * @throws ApiError
     */
    setAsDefaultSpecification(specId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/specifications/{spec_id}/set_default',
            path: {
                'spec_id': specId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    components(model, simple = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Functions
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    functions(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/functions',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Resources
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    resources(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/resources',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Performers
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    performers(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/performers',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Systems
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    systems(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/systems',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capabilities
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    capabilities1(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/capabilities',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Standards
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    standards(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/standards',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objectives
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    objectives(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/objectives',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Benefits
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    benefits(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/benefits',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Kpis
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    kpis(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/kpis',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stakeholders
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    stakeholders(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/stakeholders',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Assumptions
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    assumptions(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/assumptions',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dependencies
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    dependencies(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/dependencies',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Project Constraints
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    projectConstraints(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/project_constraints',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ideas Endpoint
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ideasEndpoint(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Idea
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createIdea(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/ideas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Idea
     * @param ideaId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdea(ideaId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Idea
     * @param ideaId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateIdea(ideaId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Idea
     * @param ideaId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteIdea(ideaId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Idea Sources
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ideaSources(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas/sources',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/user',
        });
    }
    /**
     * Get Shared Models
     * Get all models shared with the given user
     * @returns any Successful Response
     * @throws ApiError
     */
    getSharedModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/shared',
        });
    }
    /**
     * Get Public Models
     * Get all public models in the database
     * @returns any Successful Response
     * @throws ApiError
     */
    getPublicModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/public',
        });
    }
    /**
     * Get Bts
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBts(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/bts',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Model
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportModel(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Model
     * @returns any Successful Response
     * @throws ApiError
     */
    importModel1() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/models/import',
        });
    }
    /**
     * Get Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    getNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Put Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    putNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Create Notifications Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    createNotificationsEndpoints() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/notifications',
        });
    }
    /**
     * Set Notification Read
     * Set a single Notification by its ID to "has been read"
                                     * :param _notification_id:
                                     * :return:
     * @param notificationId
     * @returns any Successful Response
     * @throws ApiError
     */
    setNotificationRead(notificationId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/notifications/{_notification_id}',
            path: {
                '_notification_id': notificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ping
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ping(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/utils/ping',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Details
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    details(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/utils/details',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * All Constants
     * @returns any Successful Response
     * @throws ApiError
     */
    allConstants() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/constants',
        });
    }
}
