import { apiStatus } from '@/enums'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

const getDefaultState = () => ({
  targetPosition: undefined,
  selected_requirement: { properties: { id: '' } },
  selected_requirements: [],
  specificationRequirements: [],
  requirements: [],
  requirement_options: [],
  requirements_fields: [],
  initial_section_relation: null,
  requirements_status: apiStatus.INIT,
  selected_requirement_status: apiStatus.INIT,
  cacheLastRefreshTime: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getRequirementById: (state, requirementId) => () => state.requirements.find(({ id }) => id === requirementId),
  },
  mutations: {
    SET_TARGET_POSITION: (state, data) => { state.targetPosition = data },
    CLEAR_TARGET_POSITION: state => { state.targetPosition = undefined },
    SET_SELECTED_REQUIREMENTS: (state, requirements) => { state.selected_requirements = requirements },
    SET_REQUIREMENTS: (state, requirements) => { state.requirements = requirements },
    INVALIDATE_CACHE: state => { state.requirements = []; state.cacheLastRefreshTime = null },
    SET_REQUIREMENTS_STATUS: (state, requirementsStatus) => { state.requirements_status = requirementsStatus },
    SET_REQUIREMENTS_FIELDS: (state, fields) => { state.requirements_fields = fields },
    SET_SELECTED_REQUIREMENT: (state, selectedRequirement) => { state.selected_requirement = selectedRequirement },
    SET_SELECTED_REQUIREMENT_STATUS: (state, status) => { state.selected_requirement_status = status },
    SET_SPEC_REQUIREMENTS: (state, specificationRequirements) => { state.specificationRequirements = specificationRequirements },
    SET_REQUIREMENT_OPTIONS: (state, opts) => { state.requirement_options = opts },
    SET_INITIAL_SECTION_RELATION: (state, relation) => { state.initial_section_relation = relation },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    getRequirements: ({ commit, rootState }, spec) => {
      commit('SET_REQUIREMENTS_STATUS', apiStatus.LOADING)
      const params = { model: rootState.model.id }
      if (spec) {
        return coreService
          .get(`/v1/legacy/requirements/for_spec/${spec}`, { params })
          .then(({ data }) => {
            const attributeSet = new Set()
            data.forEach(requirement => Object.keys(requirement).forEach(attr => attributeSet.add(attr)))
            commit('SET_REQUIREMENTS_FIELDS', [...attributeSet].map((attr, index) => Object({
              field: attr,
              label: attr.split('_').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' '),
            })))
            commit('SET_REQUIREMENTS', data)
            commit('SET_REQUIREMENTS_STATUS', apiStatus.LOADED)
          })
          .catch(e => console.error(e))
      }
      return coreService.get('/v1/legacy/requirements', { params })
        .then(({ data }) => {
          const attributeSet = new Set()
          data.forEach(requirement => Object.keys(requirement).forEach(attr => attributeSet.add(attr)))
          commit('SET_REQUIREMENTS_FIELDS', [...attributeSet].map((attr, index) => Object({
            field: attr,
            label: attr.split('_').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' '),
          })))
          commit('SET_REQUIREMENTS', data)
          commit('SET_REQUIREMENTS_STATUS', apiStatus.LOADED)
        })
    },
    invalidateCache: ({ commit }) => {
      commit('INVALIDATE_CACHE')
    },
    getRequirementsSimple: ({ commit, rootState }, payload) => {
      const forced = payload?.forced
      const params = { model: rootState.model.id }
      if (rootState.requirements.cacheLastRefreshTime && rootState.requirements.requirements.length > 0
          && Date.now() - rootState.requirements.cacheLastRefreshTime < 60000 && !forced) {
        return Promise.resolve()
      }
      commit('SET_REQUIREMENTS_STATUS', apiStatus.LOADING)
      rootState.requirements.cacheLastRefreshTime = Date.now()
      return coreService.get('/v1/legacy/requirements/get_requirements_simple', { params })
        .then(({ data }) => {
          commit('SET_REQUIREMENTS', data)
          commit('SET_REQUIREMENTS_STATUS', apiStatus.LOADED)
        })
    },
    getSpecificationRequirements: ({ commit, rootState }, spec) => {
      const params = { model: rootState.model.id }
      return coreService
        .get(`/v1/legacy/specifications/${spec}`, { params })
        .then(({ data }) => { commit('SET_SPEC_REQUIREMENTS', data) })
        .catch(e => console.error(e))
    },
    updateRequirementOptions({ commit, rootState }, specId) {
      const params = { model: rootState.model.id, spec: specId }
      return coreService
        .get('/v1/legacy/requirements/options', { params })
        .then(({ data }) => { commit('SET_REQUIREMENT_OPTIONS', data) })
        .catch(e => console.error(e))
    },
    selectRequirement: async ({ commit, rootState }, requirementId) => {
      const params = { model: rootState.model.id }
      commit('SET_SELECTED_REQUIREMENT', { properties: { id: requirementId } })
      commit('SET_SELECTED_REQUIREMENT_STATUS', apiStatus.LOADING)
      const result = await coreService.get(`/v1/legacy/requirements/${requirementId}/context`, { params })
      const { data } = result
      commit('SET_SELECTED_REQUIREMENT', data)
      commit('SET_SELECTED_REQUIREMENT_STATUS', apiStatus.LOADED)
    },
    reselectRequirement: ({ dispatch, state }) => dispatch('selectRequirement', state.selected_requirement.properties.id),
    createRequirement: ({
      dispatch, state, commit, rootState,
    }, data) => {
      const params = { model: rootState.model.id }
      const payload = {
        properties: data,
        model_id: rootState.model.id,
        spec_id: data.spec_id,
        targetPosition: state.targetPosition,
      }
      return coreService
        .post('/v1/legacy/requirements', payload, { params })
        .then(({ data }) => {
          dispatch('requirementsTableLegacy/getData', { requirements: [data.id], setLayout: true }, { root: true })
          dispatch('selectRequirement', data.id)
          return data.id
        })
        .catch(e => console.error(e))
        .finally(() => {
          commit('CLEAR_TARGET_POSITION')
          commit('INVALIDATE_CACHE')
        })
    },
    bulkAddRequirement: ({ dispatch, commit, rootState }, { formData, specification }) => {
      const config = { headers: { 'Model-Id': store.state.model.id } }
      return coreService.post(`/v1/requirement/${specification}/bulk_add_csv`, formData, config).then(r => {
        // Store the task_id in the task list to watch progress
        store.commit('workerTasks/ADD_TASK_TO_LIST', r.data.task_id)
      })
    },
    editRequirement: ({ dispatch, commit, rootState }, { id, requirement }) => {
      const params = { model: rootState.model.id }
      commit('INVALIDATE_CACHE')
      return coreService
        .put(`/v1/legacy/requirements/${id}`, requirement, { params })
        .then(({ data }) => dispatch('requirementsTableLegacy/getData', { setLayout: true }, { root: true }))
        .catch(e => console.error(`[Vuex/Requirements::editRequirement] Failed to edit Requirement with ID: ${id} - ${e}`))
    },
    patchRequirement: ({
      dispatch, state, commit, rootState,
    }, payload) => {
      const params = { model: rootState.model.id }
      commit('INVALIDATE_CACHE')
      return coreService.patch(`/v1/legacy/requirements/${state.selected_requirement.properties.id}`, payload, { params })
        .then(({ data }) => {
          dispatch('selectRequirement', state.selected_requirement.properties.id)
        })
    },
    bulkEditRequirement: ({ dispatch, rootState }, { ids, requirement }) => {
      const params = { model: rootState.model.id }
      ids.forEach(id => {
        coreService.put(`/v1/legacy/requirements/${id}`, requirement, { params }).then(() => {
          if (id === ids[ids.length - 1]) {
            dispatch('requirementsTableLegacy/getData', { setLayout: true }, { root: true })
          }
        })
      })
    },
    copyRequirement: ({ rootState, dispatch }, payload) => {
      const config = { headers: { 'Model-Id': rootState.model.id } }
      return coreService
        .post('/v1/requirement/copy', payload, config)
        .then(({ data }) => data)
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Copy requirement error',
              text: e.response.data.detail,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    moveRequirement: ({ rootState, dispatch }, payload) => {
      const config = { headers: { 'Model-Id': rootState.model.id } }
      return coreService
        .post('/v1/requirement/move', payload, config)
        .then(({ data }) => data)
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Move requirement error',
              text: e.response.data.detail,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteRequirement: ({ rootState, dispatch }, requirementId) => {
      const config = { headers: { 'Model-Id': rootState.model.id } }
      return coreService
        .delete(`/v1/requirement/${requirementId}`, config)
        .then(({ data }) => {
          console.log(data)
          dispatch('requirementsTableLegacy/getData', { setLayout: true }, { root: true })
          return data
        })
        .catch(e => e.response)
    },
    restoreRequirement: ({ rootState, dispatch }, requirementId) => {
      const config = { headers: { 'Model-Id': rootState.model.id } }
      return coreService
        .get(`/v1/requirement/${requirementId}/restore`, config)
        .then(({ data }) => {
          dispatch('requirementsTableLegacy/getData', { setLayout: true }, { root: true })
          return data
        })
        .catch(e => e.response)
    },
    clearSelectedRequirement: ({ commit }) => {
      commit('SET_SELECTED_REQUIREMENT', { properties: { id: '' } })
    },
    clearRequirements: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
