import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdminCliApi } from './services/AdminCliApi';
import { ApprovalsApi } from './services/ApprovalsApi';
import { AuthenticationFlowApi } from './services/AuthenticationFlowApi';
import { BehaviourApi } from './services/BehaviourApi';
import { BehavioursApi } from './services/BehavioursApi';
import { BehaviourTreesApi } from './services/BehaviourTreesApi';
import { CanvasApi } from './services/CanvasApi';
import { ClassDiagramsApi } from './services/ClassDiagramsApi';
import { CollectionsApi } from './services/CollectionsApi';
import { CommentsApi } from './services/CommentsApi';
import { ComponentApi } from './services/ComponentApi';
import { ComponentsApi } from './services/ComponentsApi';
import { ConstantsApi } from './services/ConstantsApi';
import { ContentApi } from './services/ContentApi';
import { DefaultApi } from './services/DefaultApi';
import { DomainModelApi } from './services/DomainModelApi';
import { GroupManagementApi } from './services/GroupManagementApi';
import { HistoryApi } from './services/HistoryApi';
import { IdeasApi } from './services/IdeasApi';
import { InterfacesApi } from './services/InterfacesApi';
import { IssuesApi } from './services/IssuesApi';
import { LegacyApi } from './services/LegacyApi';
import { ModelApi } from './services/ModelApi';
import { ModelsApi } from './services/ModelsApi';
import { NoteApi } from './services/NoteApi';
import { NotificationsApi } from './services/NotificationsApi';
import { QualificationEvidenceApi } from './services/QualificationEvidenceApi';
import { QualificationRecordsApi } from './services/QualificationRecordsApi';
import { ReleasesApi } from './services/ReleasesApi';
import { RequirementApi } from './services/RequirementApi';
import { RequirementsApi } from './services/RequirementsApi';
import { ReviewsApi } from './services/ReviewsApi';
import { SessionManagementApi } from './services/SessionManagementApi';
import { SnapshotsApi } from './services/SnapshotsApi';
import { SpecificationsApi } from './services/SpecificationsApi';
import { TasksApi } from './services/TasksApi';
import { TestsApi } from './services/TestsApi';
import { TradesApi } from './services/TradesApi';
import { TradeStudiesApi } from './services/TradeStudiesApi';
import { UserManagementApi } from './services/UserManagementApi';
import { UsersManagementApi } from './services/UsersManagementApi';
import { UtilsApi } from './services/UtilsApi';
export class KompCoreApiClient {
    adminCli;
    approvals;
    authenticationFlow;
    behaviour;
    behaviours;
    behaviourTrees;
    canvas;
    classDiagrams;
    collections;
    comments;
    component;
    components;
    constants;
    content;
    default;
    domainModel;
    groupManagement;
    history;
    ideas;
    interfaces;
    issues;
    legacy;
    model;
    models;
    note;
    notifications;
    qualificationEvidence;
    qualificationRecords;
    releases;
    requirement;
    requirements;
    reviews;
    sessionManagement;
    snapshots;
    specifications;
    tasks;
    tests;
    trades;
    tradeStudies;
    userManagement;
    usersManagement;
    utils;
    request;
    constructor(config, HttpRequest = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.adminCli = new AdminCliApi(this.request);
        this.approvals = new ApprovalsApi(this.request);
        this.authenticationFlow = new AuthenticationFlowApi(this.request);
        this.behaviour = new BehaviourApi(this.request);
        this.behaviours = new BehavioursApi(this.request);
        this.behaviourTrees = new BehaviourTreesApi(this.request);
        this.canvas = new CanvasApi(this.request);
        this.classDiagrams = new ClassDiagramsApi(this.request);
        this.collections = new CollectionsApi(this.request);
        this.comments = new CommentsApi(this.request);
        this.component = new ComponentApi(this.request);
        this.components = new ComponentsApi(this.request);
        this.constants = new ConstantsApi(this.request);
        this.content = new ContentApi(this.request);
        this.default = new DefaultApi(this.request);
        this.domainModel = new DomainModelApi(this.request);
        this.groupManagement = new GroupManagementApi(this.request);
        this.history = new HistoryApi(this.request);
        this.ideas = new IdeasApi(this.request);
        this.interfaces = new InterfacesApi(this.request);
        this.issues = new IssuesApi(this.request);
        this.legacy = new LegacyApi(this.request);
        this.model = new ModelApi(this.request);
        this.models = new ModelsApi(this.request);
        this.note = new NoteApi(this.request);
        this.notifications = new NotificationsApi(this.request);
        this.qualificationEvidence = new QualificationEvidenceApi(this.request);
        this.qualificationRecords = new QualificationRecordsApi(this.request);
        this.releases = new ReleasesApi(this.request);
        this.requirement = new RequirementApi(this.request);
        this.requirements = new RequirementsApi(this.request);
        this.reviews = new ReviewsApi(this.request);
        this.sessionManagement = new SessionManagementApi(this.request);
        this.snapshots = new SnapshotsApi(this.request);
        this.specifications = new SpecificationsApi(this.request);
        this.tasks = new TasksApi(this.request);
        this.tests = new TestsApi(this.request);
        this.trades = new TradesApi(this.request);
        this.tradeStudies = new TradeStudiesApi(this.request);
        this.userManagement = new UserManagementApi(this.request);
        this.usersManagement = new UsersManagementApi(this.request);
        this.utils = new UtilsApi(this.request);
    }
}
