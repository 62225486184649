export class RequirementsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Requirement
     * Get a Requirement with the given unique {id} that belongs to {model_id}
 * :param id: UUID of the Requirement node
 * :param request: HTTP Request handler, used to include User information
 * :param response: HTTP Response handler, used to include ETag header
 * :param model_id: UUID of the Model node
 * :return: The found Requirement node or a 404 HTTPException if not found
 * :rtype: Requirement
     * @param id
     * @param modelId
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    getRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Head
     * Get the ETag for a Requirement with the given unique {id} that belongs to {model_id}
 * :param id: UUID of the Requirement node
 * :param request: HTTP Request handler, used to include User information
 * :param response: HTTP Response handler, used to include ETag header
 * :param model_id: UUID of the Model node
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementHead(id, modelId) {
        return this.httpRequest.request({
            method: 'HEAD',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param id
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    updateRequirement(id, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirement(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement',
            headers: {
                'spec-id': specId,
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Delete Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkDeleteRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/delete',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Bulk Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBulkRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/update_bulk',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Restore Requirement
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    restoreRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{id}/restore',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/copy',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Requirements
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    moveRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/move',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reorder Requirement
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    reorderRequirement(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/reorder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Add Requirements
     * @param specId
     * @param modelId
     * @param formData
     * @returns void
     * @throws ApiError
     */
    bulkAddRequirements(specId, modelId, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{spec_id}/bulk_add_csv',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Trace
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementTrace(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Or Update Trace Links
     * @param reqId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createOrUpdateTraceLinks(reqId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Coverage
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementCoverage(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/coverage',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Or Update Coverage Links
     * @param reqId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createOrUpdateCoverageLinks(reqId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{req_id}/coverage',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read Trace Link
     * @param traceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    readTraceLink(traceId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trace Link
     * @param traceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTraceLink(traceId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trace Link
     * @param traceId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTraceLink(traceId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Allocations
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementAllocations(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/allocations',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Links
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementLinks(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/links',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Issues For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualIssuesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Issue Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createIssueLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Issue Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeIssueLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Tests For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualTestsForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Test Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createTestLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Test Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeTestLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Releases For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualReleasesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Release Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createReleaseLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Release Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeReleaseLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Interfaces For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualInterfacesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Interface Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterfaceLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Interface Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeInterfaceLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Simple Requirements For Model
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSimpleRequirementsForModel(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/get_requirements_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements
     * Get all requirements for the given model id
 * :param kwargs:
 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirements(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirement1(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirement Context
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementContext(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}/context',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement
     * Get all requirement by given id for the given model id
 * :param req_id: ID of the Requirement to query
 * :return:
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    patchRequirement(reqId, model) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/requirements/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Restore Requirement
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    restoreRequirement1(reqId, model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/{req_id}/restore',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements Table
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsTable(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/table_data',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Table Layout
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTableLayout(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/table_layout',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Requirements
     * Get all requirements for the given model id
 * :param spec:
 * :param kwargs:
 * :return:
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationRequirements(spec, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/for_spec/{spec}',
            path: {
                'spec': spec,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Docx Templates
     * Get a list of all .docx template files in the 'instance/uploads' folder
 * :return: List[str]
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDocxTemplates(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/docx_templates',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Requirements Docx
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportRequirementsDocx(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Trace Report
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportTraceReport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/trace_report',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Distinct Requirement Attributes For Model Or Spec
     * @param model
     * @param specification
     * @param forUpload
     * @returns any Successful Response
     * @throws ApiError
     */
    listDistinctRequirementAttributesForModelOrSpec(model, specification, forUpload = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes',
            query: {
                'model': model,
                'specification': specification,
                'for_upload': forUpload,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Attributes For Spec
     * @param spec
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementAttributesForSpec(spec) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes/for_spec/{spec}',
            path: {
                'spec': spec,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Specification
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementSpecification(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/specifications',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Options
     * @param model
     * @param spec
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementOptions(model, spec) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/options',
            query: {
                'model': model,
                'spec': spec,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Req Trace
     * @param reqId
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getReqTrace(reqId, specId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/trace/{req_id}/{spec_id}',
            path: {
                'req_id': reqId,
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Ids
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementIds(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/ids',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement Trace
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirementTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/trace',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Backwards Trace
     * JSON input expectations
 * trace: [....],
 * id: <<id of the requirement tracing backward from>>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createBackwardsTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/create_backward_traces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Forward Trace
     * JSON input expectations
 * sources: [....],
 * target: <<id of the requirement tracing forward from>>
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createForwardTrace(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/create_forward_traces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Requirement2
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    parseRequirement2(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/{req_id}/parse2',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Parse Results2
     * Use parsing results to create components, relations, behaviours and issues
 * :return:
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateParseResults2(reqId, model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/{req_id}/associate_parse2',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Req Fns
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReqFns(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_req_fns',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Entities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateEntities(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_entities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Performers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associatePerformers(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_performers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Enablers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateEnablers(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_enablers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Beneficiaries
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateBeneficiaries(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_beneficiaries',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate References
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReferences(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_references',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Releases
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateReleases(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_releases',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Functions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateFunctions(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_functions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateInterfaces(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Resources
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateResources(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_resources',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Objectives
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateObjectives(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Parse Results
     * TODO: Should be able to delete this
 * Use parsing results to create components, relations, behaviours and issues
 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateParseResults(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/requirements/associate_parse',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Additional
     * @param reqId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    additional(reqId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/attributes/{req_id}',
            path: {
                'req_id': reqId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements Report
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementsReport(spec, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/requirements_report/{spec}',
            path: {
                'spec': spec,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Requirements Report
     * @param spec
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    requirementsReport1(spec, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/requirements/requirements_report',
            query: {
                'spec': spec,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
