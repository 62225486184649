export class ReleasesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Releases
     * @param model
     * @param sorting
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllReleases(model, sorting = 'end') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/releases',
            query: {
                'model': model,
                'sorting': sorting,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Release
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createRelease(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/releases',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Release
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRelease(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/releases/delete/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Release
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRelease(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/releases/update/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Release
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getRelease(id, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/releases/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
