export class IdeasApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Ideas Endpoint
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ideasEndpoint(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Idea
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createIdea(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/ideas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Idea
     * @param ideaId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdea(ideaId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Idea
     * @param ideaId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateIdea(ideaId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Idea
     * @param ideaId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteIdea(ideaId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/ideas/{idea_id}',
            path: {
                'idea_id': ideaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Idea Sources
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ideaSources(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/ideas/sources',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
