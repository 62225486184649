import router from '@/router'
import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  assumptions: [],
  benefits: [],
  constraints: [],
  dependencies: [],
  kpis: [],
  objectives: [],
  capabilities: [],
  stakeholders: [],

  // Selected entities
  selectedAssumption: false,
  selectedBenefit: false,
  selectedConstraint: false,
  selectedDependency: false,
  selectedKPI: false,
  selectedObjective: false,
  selectedCapability: false,
  selectedStakeholder: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    // Set Mutations
    SET_CANVAS: (state, canvas) => {
      state.assumptions = canvas.assumptions
      state.benefits = canvas.benefits
      state.constraints = canvas.constraints
      state.dependencies = canvas.dependencies
      state.kpis = canvas.kpis
      state.objectives = canvas.objectives
      state.stakeholders = canvas.stakeholders
    },
    SET_ASSUMPTIONS: (state, assumptions) => { state.assumptions = assumptions },
    SET_BENEFITS: (state, benefits) => { state.benefits = benefits },
    SET_CONSTRAINTS: (state, constraints) => { state.constraints = constraints },
    SET_DEPENDENCIES: (state, dependencies) => { state.dependencies = dependencies },
    SET_KPIS: (state, kpis) => { state.kpis = kpis },
    SET_OBJECTIVES: (state, objectives) => { state.objectives = objectives },
    SET_CAPABILITIES: (state, capabilities) => { state.capabilities = capabilities },
    SET_STAKEHOLDERS: (state, stakeholders) => { state.stakeholders = stakeholders },

    // Select Mutations
    SET_SELECTED_ASSUMPTION: (state, assumption) => { state.selectedAssumption = assumption },
    SET_SELECTED_CONSTRAINT: (state, constraint) => { state.selectedConstraint = constraint },
    SET_SELECTED_DEPENDENCY: (state, dependency) => { state.selectedDependency = dependency },
    SET_SELECTED_OBJECTIVE: (state, objective) => { state.selectedObjective = objective },
    SET_SELECTED_CAPABILITY: (state, capability) => { state.selectedCapability = capability },
    SET_SELECTED_STAKEHOLDER: (state, stakeholder) => { state.selectedStakeholder = stakeholder },
    SET_SELECTED_OBJECTIVE_BENEFITS: (state, benefits) => { state.selectedObjective.benefits = benefits },
    SET_SELECTED_OBJECTIVE_KPIS: (state, kpis) => { state.selectedObjective.kpis = kpis },

    // Clear Mutations
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
    CLEAR_SELECTED_ASSUMPTION: state => { state.selectedAssumption = false },
    CLEAR_SELECTED_BENEFIT: state => { state.selectedBenefit = false },
    CLEAR_SELECTED_CONSTRAINT: state => { state.selectedConstraint = false },
    CLEAR_SELECTED_DEPENDENCY: state => { state.selectedDependency = false },
    CLEAR_SELECTED_KPI: state => { state.selectedKPI = false },
    CLEAR_SELECTED_OBJECTIVE: state => { state.selectedObjective = false },
    CLEAR_SELECTED_CAPABILITY: state => { state.selectedCapability = false },
    CLEAR_SELECTED_STAKEHOLDER: state => { state.selectedStakeholder = false },
  },
  actions: {
    fetchCanvas: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas', { params })
        .then(({ data }) => {
          commit('SET_CANVAS', data)
        })
        .catch(e => console.error(e))
    },
    clearCanvas: ({ commit }) => {
      commit('CLEAR_ALL')
    },

    // Fetch Actions
    fetchAssumptions: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/assumptions', { params })
        .then(({ data }) => {
          commit('SET_ASSUMPTIONS', data)
        })
        .catch(e => console.error(e))
    },
    fetchBenefits: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/benefits', { params })
        .then(({ data }) => {
          commit('SET_BENEFITS', data)
        })
        .catch(e => console.error(e))
    },
    fetchConstraints: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/constraints', { params })
        .then(({ data }) => {
          commit('SET_CONSTRAINTS', data)
        })
        .catch(e => console.error(e))
    },
    fetchDependencies: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/dependencies', { params })
        .then(({ data }) => {
          commit('SET_DEPENDENCIES', data)
        })
        .catch(e => console.error(e))
    },
    fetchKPIs: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/kpis', { params })
        .then(({ data }) => {
          commit('SET_KPIS', data)
        })
        .catch(e => console.error(e))
    },
    fetchObjectives: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/objectives', { params })
        .then(({ data }) => {
          commit('SET_OBJECTIVES', data)
        })
        .catch(e => console.error(e))
    },
    fetchCapabilities: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/capabilities', { params })
        .then(({ data }) => {
          commit('SET_CAPABILITIES', data)
        })
        .catch(e => console.error(e))
    },
    fetchStakeholders: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get('/v1/legacy/canvas/stakeholders', { params })
        .then(({ data }) => {
          commit('SET_STAKEHOLDERS', data)
        })
        .catch(e => console.error(e))
    },

    // Select Actions
    selectAssumption: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/assumptions/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_ASSUMPTION', data)
        })
        .catch(e => console.error(e))
    },
    selectConstraint: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/constraints/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_CONSTRAINT', data)
        })
        .catch(e => console.error(e))
    },
    selectDependency: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/dependencies/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_DEPENDENCY', data)
        })
        .catch(e => console.error(e))
    },
    selectObjective: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/objectives/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_OBJECTIVE', data)
        })
        .catch(e => console.error(e))
    },
    selectCapability: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/capabilities/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_CAPABILITY', data)
        })
        .catch(e => console.error(e))
    },
    selectStakeholder: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/canvas/stakeholders/${id}`, { params })
        .then(({ data }) => {
          commit('SET_SELECTED_STAKEHOLDER', data)
        })
        .catch(e => console.error(e))
    },

    // Create Actions
    createAssumption: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post('/v1/legacy/canvas/assumptions', data, { params })
        .then(() => {
          dispatch('fetchAssumptions')
        })
        .catch(e => console.error(e))
    },
    createBenefit: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .post('/v1/legacy/canvas/benefits', data, { params })
        .then(({ data }) => {
          dispatch('fetchBenefits')
          return data.id
        })
        .catch(e => console.error(e))
    },
    createConstraint: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post('/v1/legacy/canvas/constraints', data, { params })
        .then(() => {
          dispatch('fetchConstraints')
        })
        .catch(e => console.error(e))
    },
    createDependency: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post('/v1/legacy/canvas/dependencies', data, { params })
        .then(() => {
          dispatch('fetchDependencies')
        })
        .catch(e => console.error(e))
    },
    createKPI: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.post('/v1/legacy/canvas/kpis', data, { params })
        .then(({ data }) => {
          dispatch('fetchKPIs')
          return data.id
        })
        .catch(e => console.error(e))
    },
    createObjective: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .post('/v1/legacy/canvas/objectives', data, { params })
        .then(() => {
          dispatch('fetchObjectives')
        })
        .catch(e => console.error(e))
    },
    createCapability: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .post('/v1/legacy/canvas/capabilities', data, { params })
        .then(() => {
          dispatch('fetchCapabilities')
        })
        .catch(e => console.error(e))
    },
    createStakeholder: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .post('/v1/legacy/canvas/stakeholders', data, { params })
        .then(() => {
          dispatch('fetchStakeholders')
        })
        .catch(e => console.error(e))
    },

    // Update Actions
    updateAssumption: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/assumptions/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchAssumptions')
        })
        .catch(e => console.error(e))
    },
    updateBenefit: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/benefits/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchBenefits')
        })
        .catch(e => console.error(e))
    },
    updateConstraint: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/constraints/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchConstraints')
        })
        .catch(e => console.error(e))
    },
    updateDependency: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/dependencies/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchDependencies')
        })
        .catch(e => console.error(e))
    },
    updateKPI: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/kpis/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchKPIs')
        })
        .catch(e => console.error(e))
    },
    updateObjective: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/objectives/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchObjectives')
        })
        .catch(e => console.error(e))
    },
    updateCapability: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/capabilities/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchCapabilities')
        })
        .catch(e => console.error(e))
    },
    updateStakeholder: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .put(`/v1/legacy/canvas/stakeholders/${data.id}`, data, { params })
        .then(() => {
          dispatch('fetchStakeholders')
        })
        .catch(e => console.error(e))
    },

    // Delete Actions
    deleteAssumption: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/assumptions/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_ASSUMPTION')
          dispatch('fetchAssumptions')
        })
        .catch(e => console.error(e))
    },
    deleteBenefit: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/benefits/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_BENEFIT')
          dispatch('fetchBenefits')
        })
        .catch(e => console.error(e))
    },
    deleteConstraint: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/constraints/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_CONSTRAINT')
          dispatch('fetchConstraints')
        })
        .catch(e => console.error(e))
    },
    deleteDependency: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/dependencies/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_DEPENDENCY')
          dispatch('fetchDependencies')
        })
        .catch(e => console.error(e))
    },
    deleteKPI: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/kpis/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_KPI')
          dispatch('fetchKPIs')
        })
        .catch(e => console.error(e))
    },
    deleteObjective: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/objectives/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_OBJECTIVE')
          dispatch('fetchObjectives')
        })
        .catch(e => console.error(e))
    },
    deleteCapability: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/canvas/capabilities/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_CAPABILITY')
          dispatch('fetchCapabilities')
        })
        .catch(e => console.error(e))
    },
    deleteStakeholder: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService.delete(`/v1/legacy/canvas/stakeholders/${id}`, { params })
        .then(() => {
          commit('CLEAR_SELECTED_STAKEHOLDER')
          dispatch('fetchStakeholders')
        })
        .catch(e => console.error(e))
    },
  },
}
