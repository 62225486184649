export class TestsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create Test
     * Create a new TestCase
 * Requires JSON in the request
 * :return: 201 status - created
     * @returns any Successful Response
     * @throws ApiError
     */
    createTest() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/create_test',
        });
    }
    /**
     * Test Details
     * GET/UPDATE/DELETE test (and associated details) with the given test_id
 * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    testDetails(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/details/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Page Number Finder
     * Identify the page number a test case would be on given a per page limit
 * :return: JSON object of TestCase id, page number
     * @param testId
     * @param modelId
     * @param perPage
     * @returns any Successful Response
     * @throws ApiError
     */
    pageNumberFinder(testId, modelId = '', perPage = 100) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/page_number_identifier/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'modelId': modelId,
                'perPage': perPage,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get External Test
     * GET/UPDATE/DELETE test with the foreign (ref_id) of test_id
 * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put External Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete External Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteExternalTest(testId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/tests/external_test/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTest(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Requirement
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithRequirement() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_requirement',
        });
    }
    /**
     * Associate Test With Requirements
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithRequirements(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_requirements',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Components
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithComponents(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Instances
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithInstances(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_instances',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Test With Configs
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    associateTestWithConfigs(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/associate_test_with_configs',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Components With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedComponentsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_components',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Instances With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedInstancesWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_instances',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Requirements With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedRequirementsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_requirements',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Configurations With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedConfigurationsWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_configurations',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Associated Behaviours With Test
     * @param testId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssociatedBehavioursWithTest(testId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_associated_behaviours',
            query: {
                'test_id': testId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Test Associations
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTestAssociations() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/delete_associations',
        });
    }
    /**
     * Delete Test Requirement Associations
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTestRequirementAssociations() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/delete_test_to_requirement_association',
        });
    }
    /**
     * Get External Tests By Requirement And Model
     * This endpoint was created for external use
 * Get all tests using the requirement ID and model ID
 * Important note:
 * What the external people understand as the requirement ID is = display_id in our database
 * :return: JSON object of tests
     * @param requirement
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getExternalTestsByRequirementAndModel(requirement, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_tests_by_requirement_and_model',
            query: {
                'requirement': requirement,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Log Test Execution
     * Record a Test Execution against a Test Case
 * :payload {"build_id", "datetime", "result"}
 * :return: JSON object of the Test Execution
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    logTestExecution(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/log_test_execution',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Executions
     * Get a list of all TestExecutions for the given TestCase
 * :return: JSON array of all Test Executions
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestExecutions(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_executions',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Test Executions
     * @param testUuid
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    putTestExecutions(testUuid, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/get_executions',
            query: {
                'test_uuid': testUuid,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Procedures
     * Get a list of all TestProcedures and their details
 * :return: JSON array of all Test Procedures
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestProcedures(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/test_procedures',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Procedure
     * Get a TestProcedures and its details
 * :return: JSON array of Test Procedure
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestProcedure(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/test_procedures/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test In Behavior Tree
     * Get a list of all TestExecutions for the given Behavior Tree
 * :return: JSON array of all Test Executions
     * @param behaviorTreeId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestInBehaviorTree(behaviorTreeId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_tests_in_behavior_tree',
            query: {
                'behavior_tree_id': behaviorTreeId,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Test Conditions
     * Get a list of all testable conditions for all element attributes
 * :return: JSON array of all Test Executions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestConditions(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/get_test_conditions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Test Cases
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    importTestCases(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests/import',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Tests
     * Get all tests for the given model id
 * :return: JSON array of TestCases
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    tests(model, simple = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Tests
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    postTests(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/tests',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * GET/UPDATE/DELETE test with the given test_id
 * :return: JSON object of TestCase
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test(testId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test1(testId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @param testId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    test2(testId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/tests/{test_id}',
            path: {
                'test_id': testId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
