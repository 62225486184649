import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const getDefaultState = () => ({
  // Generic stuff
  allCriteria: [],
  allOptions: [],
  allStudies: [],

  // Trade study
  selectedStudy: {},
  weights: [],
  scores: [],
  results: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_ALL_CRITERIA: (state, data) => { state.allCriteria = data },
    SET_ALL_OPTIONS: (state, data) => { state.allOptions = data },
    SET_ALL_STUDIES: (state, data) => { state.allStudies = data },
    SET_SELECTED_STUDY: (state, data) => { state.selectedStudy = data },
    SET_WEIGHTS: (state, data) => { state.weights = data },
    SET_SCORES: (state, data) => { state.scores = data },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    getBothCritsOpts: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService
        .get('/v1/legacy/trades/get_options_and_criteria', config)
        .then(({ data }) => {
          commit('SET_ALL_OPTIONS', data.options)
          commit('SET_ALL_CRITERIA', data.criteria)
        })
        .catch(e => console.error(e))
    },
    getAllTradeStudies: async ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const result = await coreService.tradeStudiesApi.getTradeStudies(modelId)
      commit('SET_ALL_STUDIES', result)
    },
    createTradeStudy: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post('/v1/legacy/trades', payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Created a Trade Study',
              text: `Created a new Trade Study: ${data.name}`,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          dispatch('getAllTradeStudies')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Selected Trade Study
    getSelectedTradeStudy: ({ commit }, tsId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }

      return coreService
        .get(`/v1/legacy/trades/${tsId}`, config)
        .then(({ data }) => {
          commit('SET_SELECTED_STUDY', data)
          return data
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateTradeStudy: ({ dispatch }, item) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService
        .patch(`/v1/legacy/trades/${item.id}`, item.payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Trade Study',
              text: `Updated Trade Study: ${data.name}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          dispatch('getSelectedTradeStudy', item.id)
          dispatch('getAllTradeStudies')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteTradeStudy: ({ dispatch }, tsId) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService
        .delete(`/v1/legacy/trades/${tsId}`, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Trade Study',
              icon: 'TrashIcon',
              variant: 'warning',
            },
          })
          dispatch('getAllTradeStudies')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    duplicateTradeStudy: ({ dispatch }, item) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${item.id}/duplicate`, item.payload, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Duplicated Trade Study',
              icon: 'TickIcon',
              variant: 'info',
            },
          })
          dispatch('getAllTradeStudies')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to duplicate Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Lock Trade Study
    lockTradeStudy: ({ dispatch }, tsId) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${tsId}/lock`, {}, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Locked Trade Study',
              icon: 'LockIcon',
              variant: 'warning',
            },
          })
          dispatch('getSelectedTradeStudy', tsId)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to lock Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    unlockTradeStudy: ({ dispatch }, tsId) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${tsId}/unlock`, {}, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unlocked Trade Study',
              icon: 'LockIcon',
              variant: 'success',
            },
          })
          dispatch('getSelectedTradeStudy', tsId)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to unlock Trade Study',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Links
    createOptionsLinks: ({ commit, dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${payload.id}/options`, payload.link, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Selected Options',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          dispatch('getSelectedTradeStudy', payload.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create Option Links',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteOptionsLinks: ({ commit, dispatch, state }, optionId) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService
        .delete(`/v1/legacy/trades/${state.selectedStudy.properties.id}/options/${optionId}`, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Option(s)',
              icon: 'TrashIcon',
              variant: 'warning',
            },
          })
          dispatch('getSelectedTradeStudy', state.selectedStudy.properties.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Option Links',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    createCriteriaLinks: ({ commit, dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${payload.id}/criteria`, payload.link, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Selected Criteria',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          dispatch('getSelectedTradeStudy', payload.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to select Criteria',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteCriteriaLinks: ({ commit, dispatch, state }, criteriaId) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService
        .delete(`/v1/legacy/trades/${state.selectedStudy.properties.id}/criteria/${criteriaId}`, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Criteria',
              icon: 'TrashIcon',
              variant: 'warning',
            },
          })
          dispatch('getSelectedTradeStudy', state.selectedStudy.properties.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Criteria',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Weights
    getWeights: ({ commit, dispatch, state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${state.selectedStudy.properties.id}/weights`, payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Set Weights',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          commit('SET_WEIGHTS', data)
          dispatch('getSelectedTradeStudy', state.selectedStudy.properties.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to set Weights',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Scores
    createScoreLinks: ({ commit, dispatch, state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
      }

      return coreService.post(`/v1/legacy/trades/${state.selectedStudy.properties.id}/score`, payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Set Scores',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          commit('SET_SCORES', data)
          dispatch('getSelectedTradeStudy', state.selectedStudy.properties.id)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create Score Links',
              text: `${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearTradeStudies: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
