<template>
  <div>
    <div class="w-100 d-inline-flex flex-row-reverse">
      <b-link class="text-body text-nowrap" @click="collapseComments = !collapseComments">
        <span class="mr-25 font-small-2">
          {{ collapseComments ? 'Show' : 'Hide' }} comments
        </span>
        <feather-icon
          icon="MessageSquareIcon"
          size="18"
          class="profile-icon mr-50"
        />
      </b-link>
    </div>
    <CommentChatBox class="mt-50" @create-comment="postComment" />

    <vue-perfect-scrollbar
      v-if="!collapseComments"
      id="review-comments"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <div v-for="(commentList) in orderedComments" :key="commentList.id">
        <CommentBox
          :comment="commentList"
        />
      </div>
    </vue-perfect-scrollbar>
    <div v-if="orderedComments && orderedComments.length === 0" class="mb-2 ml-50">
      <span class="text-muted font-small-3">No comments...</span>
    </div>

    <delete-comment-modal
      :parent-id="parentId"
      :parent-label="parentLabel"
      @delete-comment="getComments()"
    />
  </div>
</template>

<script>
import DeleteCommentModal from '@/components/Comments/Modals/DeleteComment.vue'
import CommentBox from '@/components/Comments/CommentBox.vue'
import CommentChatBox from '@/components/Comments/CommentChatBox.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'

export default {
  name: 'CommentCard',
  components: {
    VuePerfectScrollbar,
    CommentBox,
    CommentChatBox,
    DeleteCommentModal,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    parentLabel: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    collapseComments: false,
    orderedComments: [],
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),
  computed: {
    auth() {
      return this.$store.state.auth
    },
  },
  watch: {
    parentId(newVal, oldVal) {
      // console.log('Watch method old val ', oldVal, ' New Val ', newVal)
      this.getComments()
    },
  },
  mounted() {
    this.getComments()
  },
  methods: {
    getComments() {
      // console.warn('Card method ', this.parentId)
      const data = {
        label: this.parentLabel,
        id: this.parentId,
      }
      this.$store.dispatch('comments/getEntityComments', data)
        .then(data => {
          this.orderedComments = data
        })
    },
    deleteComment() {
      this.$store.dispatch('comments/selectComment', this.comment)
        .then(() => {
          this.$bvModal.show('delete-comment-modal')
          this.getComments()
        })
    },
    formatToLocalTime(date) {
      const testDateUtc = moment.utc(date)
      return moment(testDateUtc).local()
    },
    createdDateShort(date) {
      const localDate = this.formatToLocalTime(date)
      return moment(localDate).fromNow()
    },
    createdDate(date) {
      const localDate = this.formatToLocalTime(date)
      return localDate.format('LLLL')
    },
    postComment(msg) {
      this.$http.post('/v1/legacy/comments', {
        parent_id: this.parentId,
        parent_label: this.parentLabel,
        message: msg,
      })
        .then(() => {
          this.$store.dispatch('comments/getEntityComments', { label: this.parentLabel, id: this.parentId })
          this.getComments()
          this.collapseComments = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .scroll-area {
    position: relative;
    margin: auto;
    max-height: 30rem;
  }
</style>
