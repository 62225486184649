export class CanvasApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Canvas
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    canvas(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Assumptions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllAssumptions(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/assumptions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Assumptions
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    postAssumptions(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/assumptions',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Assumption
     * @param assumptionId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAssumption(assumptionId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Assumption
     * @param assumptionId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateAssumption(assumptionId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Assumption
     * @param assumptionId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteAssumption(assumptionId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/assumptions/{assumption_id}',
            path: {
                'assumption_id': assumptionId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Benefits
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllBenefits(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/benefits',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Benefit
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBenefit(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/benefits',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Benefit
     * @param benefitId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBenefit(benefitId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Benefit
     * @param benefitId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBenefit(benefitId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Benefit
     * @param benefitId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBenefit(benefitId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/benefits/{benefit_id}',
            path: {
                'benefit_id': benefitId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Constraints
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllConstraints(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/constraints',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Constraint
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createConstraint(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/constraints',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Constraint
     * @param constraintId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getConstraint(constraintId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Constraint
     * @param constraintId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateConstraint(constraintId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Constraint
     * @param constraintId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteConstraint(constraintId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/constraints/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Dependencies
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllDependencies(model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/dependencies',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Dependency
     * @param requestBody
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createDependency(requestBody, model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/dependencies',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency(dependencyId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency1(dependencyId, model, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dependency
     * @param dependencyId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getDependency2(dependencyId, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/dependencies/{dependency_id}',
            path: {
                'dependency_id': dependencyId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Kpis
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getKpis(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/kpis',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Kpis
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createKpis(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/kpis',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Kpi
     * @param kpiId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateKpi(kpiId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/kpis/{kpi_id}',
            path: {
                'kpi_id': kpiId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Kpi
     * @param kpiId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteKpi(kpiId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/kpis/{kpi_id}',
            path: {
                'kpi_id': kpiId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Objectives
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getObjectives(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Objective
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createObjective(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/objectives',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    objective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateObjective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Objective
     * @param objectiveId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteObjective(objectiveId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/objectives/{objective_id}',
            path: {
                'objective_id': objectiveId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objective Benefits
     * @param objId
     * @returns any Successful Response
     * @throws ApiError
     */
    objectiveBenefits(objId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/objective/{obj_id}/benefits',
            path: {
                'obj_id': objId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Objective Benefits
     * @param objId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateObjectiveBenefits(objId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/objective/{obj_id}/benefits',
            path: {
                'obj_id': objId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Stakeholders
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllStakeholders(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/stakeholders',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Stakeholder
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createStakeholder(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/stakeholders',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Stakeholder
     * @param stakeholderId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getStakeholder(stakeholderId, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Stakeholder
     * @param stakeholderId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateStakeholder(stakeholderId, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Stakeholder
     * @param stakeholderId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteStakeholder(stakeholderId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/stakeholders/{stakeholder_id}',
            path: {
                'stakeholder_id': stakeholderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capabilities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    capabilities(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/capabilities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Capabilities
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createCapabilities(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/canvas/capabilities',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    capability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateCapability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Capability
     * @param capabilityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteCapability(capabilityId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/canvas/capabilities/{capability_id}',
            path: {
                'capability_id': capabilityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
