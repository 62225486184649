import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  notificationsList: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_NOTIFICATIONS: (state, data) => {
      state.notificationsList = data
    },
    CLEAR_ALL: state => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    clearNotifications: ({ commit }) => {
      commit('CLEAR_ALL')
    },
    getNotifications: ({ commit, rootState }) => coreService
      .get('/v1/legacy/notifications', { params: { user: rootState.auth.email } })
      .then(({ data }) => {
        commit('SET_NOTIFICATIONS', data)
      })
      .catch(e => console.error(e)),
  },
}
