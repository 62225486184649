export class TradesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Criteria
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCriteria(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_criteria',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Options
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getOptions(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_options',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Options And Criteria
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getOptionsAndCriteria(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/get_options_and_criteria',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Criteria Comparison Matrix
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createCriteriaComparisonMatrix(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/weights',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Ts Criteria Slider
     * @param id
     * @param csId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTsCriteriaSlider(id, csId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/delete_criteria_slider/{cs_id}',
            path: {
                'id': id,
                'cs_id': csId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trades
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    trades(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Trade
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createTrade(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/options',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/options',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Options
     * @param id
     * @param optionId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyOptions(id, optionId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/options/{option_id}',
            path: {
                'id': id,
                'option_id': optionId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    postTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Trade Study Criteria
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    patchTradeStudyCriteria(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/criteria',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Criteria
     * @param id
     * @param criteriaId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyCriteria(id, criteriaId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/criteria/{criteria_id}',
            path: {
                'id': id,
                'criteria_id': criteriaId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Score
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudyScore(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/score',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Trade Study Score
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    patchTradeStudyScore(id, modelId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/trades/{id}/score',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Score
     * @param id
     * @param scoreId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudyScore(id, scoreId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/score/{score_id}',
            path: {
                'id': id,
                'score_id': scoreId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Trade Study Slider Value
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    tradeStudySliderValue(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/slider',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trade Study Slider Value
     * @param id
     * @param sliderId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTradeStudySliderValue(id, sliderId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/trades/{id}/slider/{slider_id}',
            path: {
                'id': id,
                'slider_id': sliderId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Duplicated Trade Study Options
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    duplicatedTradeStudyOptions(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/duplicate',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Lock Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    lockTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/lock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unlock Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    unlockTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/trades/{id}/unlock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Trade Study Lock
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    checkTradeStudyLock(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/trades/{id}/check_lock',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
