import coreService from '@/libs/api-services/core-service'
import router from '@/router'

const getDefaultState = () => ({
  releases: [],
  selectedRelease: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_RELEASES: (state, releases) => { state.releases = releases },
    SET_SELECTED_RELEASE: (state, release) => { state.selectedRelease = release },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearReleases: ({ commit }) => commit('CLEAR_ALL'),
    getReleases: ({ commit }, sorting = 'end') => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId, sorting }
      return coreService
        .get('/v1/legacy/releases', { params })
        .then(({ data }) => commit('SET_RELEASES', data))
        .catch(e => console.error(e))
    },
    selectRelease: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .get(`/v1/legacy/releases/${id}`, { params })
        .then(({ data }) => commit('SET_SELECTED_RELEASE', data))
        .catch(e => console.error(e))
    },
    addRelease: ({ dispatch, rootState }, data) => {
      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
        user: rootState.auth.email,
      }
      data.model = params.model
      return coreService.post('/v1/legacy/releases', data, { params })
        .then(() => dispatch('getReleases'))
        .catch(e => console.error(e))
    },
    updateRelease: ({ dispatch, rootState }, data) => {
      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
        user: rootState.auth.email,
      }
      data.model = params.model
      return coreService.post(`/v1/legacy/releases/update/${data.id}`, data, { params })
        .then(() => dispatch('getReleases'))
        .catch(e => console.error(e))
    },
    deleteRelease: ({ dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return coreService
        .delete(`/v1/legacy/releases/delete/${id}`, { params })
        .then(() => dispatch('getReleases'))
        .catch(e => console.error(e))
    },
  },
}
