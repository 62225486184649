export class BehaviourApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Fetch Behaviour Trees With Context
     * Get all Behaviour Trees in model with full context
 * :return: JSON object representation of all found Behaviour Tree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    fetchBehaviourTreesWithContext(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Tree With Context
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourTreeWithContext(btId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bt/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Function Paths
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getFunctionPaths(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_function_paths/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Unallocated Nodes
     * Get the list of all nodes in BT with no requirement allocation
 * :param behaviour_tree_id: Unique ID for a Behaviour Tree to search within
 * - ARG specification: Unique ID for a Specification to search against
 * :return: List of Behaviour Node IDs that have no Requirement allocation
     * @param behaviourTreeId
     * @param specificationId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUnallocatedNodes(behaviourTreeId, specificationId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_unallocated_nodes/{behaviour_tree_id}',
            path: {
                'behaviour_tree_id': behaviourTreeId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'specification_id': specificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reorder Siblings
     * @param bnId
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    reorderSiblings(bnId, requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/reorder_siblings/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviours For Requirement
     * @param bt
     * @param req
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehavioursForRequirement(bt, req) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviours_for_requirement/{bt}/{req}',
            path: {
                'bt': bt,
                'req': req,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Context
     * @param bt
     * @param bn
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourContext(bt, bn, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviour_context/{bt}/{bn}',
            path: {
                'bt': bt,
                'bn': bn,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Closed And Withdrawn Issues
     * @param bn
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourClosedAndWithdrawnIssues(bn) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_behaviour_closed_and_withdrawn_issues/{bn}',
            path: {
                'bn': bn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Behaviour Display
     * @param bn
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBehaviourDisplay(bn, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{bn}/display',
            path: {
                'bn': bn,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Common Requirements
     * Returns all the requirements that are shared with the behaviour nodes provided
     * @param selectedNodes
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCommonRequirements(selectedNodes, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_common_requirements',
            headers: {
                'model-id': modelId,
            },
            query: {
                'selected_nodes': selectedNodes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unassociate Requirements
     * Unassociates the requirement from selected behaviour nodes
 * Used by bulk sidebar
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    unassociateRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/unassociate_requirements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Requirements
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeRequirements(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/requirements',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Node Requirements
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateNodeRequirements(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/requirements',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Issues
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeIssues(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/issues',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Issues
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeIssues(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/issues',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Objectives
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeObjectives(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/objectives',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Objectives
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeObjectives(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/objectives',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Tests
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeTests(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/tests',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Tests
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeTests(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/tests',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Enablers
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeEnablers(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/enablers',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Enablers
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeEnablers(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/enablers',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Beneficiaries
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeBeneficiaries(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/beneficiaries',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Beneficiaries
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeBeneficiaries(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/beneficiaries',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Standards
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeStandards(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/standards',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Standards
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeStandards(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/standards',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Properties
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeProperties(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/properties',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Properties
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeProperties(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/properties',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Notes
     * @param nodeId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeNotes(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/notes',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Node Notes
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    nodeNotes(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/notes',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Node Note
     * @param nodeId
     * @param noteId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteNodeNote(nodeId, noteId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/behaviour/nodes/{node_id}/note/{note_id}',
            path: {
                'node_id': nodeId,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Node Functions
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodeFunctions(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/nodes/{node_id}/functions',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Functions
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodeFunctions(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/{node_id}/functions',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Node Timing
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    nodeTiming(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/nodes/timing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Node Timing
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateNodeTiming(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/update_node_timing/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Node Timing
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteNodeTiming(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/delete_node_timing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Functions
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateFunctions(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_functions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Requirements
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_requirements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Issues
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateIssues(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_issues',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Objectives
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateObjectives(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_objectives',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Tests
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateTests(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_tests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Notes
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateNotes(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Enablers
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateEnablers(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_enablers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Associate Properties
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkAssociateProperties(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/bulk_associate_properties',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Behaviour Text
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    parseBehaviourText(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/parse_behaviour_text',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Parse Behaviour Text2
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    parseBehaviourText2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/parse_behaviour_text2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Bn
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBn(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_bn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Behaviour Node
     * update behavour node with <<bn_id>> with json properties
 * json: {
     * "component":  BN subject component (name or id)
     * "type":  Event | State | ...
     * "objects" [{"preposition": ..., "object": ...}]
     * "io_resource": Resource component (name or id) - used in Inputs and Outputs only
     * ...other fields to augment BN information...
     * }
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBehaviourNode(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/behaviour/update_behaviour/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Behaviour Properties
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBehaviourProperties(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/update_behaviour_properties/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Bn
     * Delete bn and optionally children
     * json...{bn: <<bn_id>>, bt: <<bt_id>>, delete_children: True|[False*]
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBn(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/delete_bn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bn Descendents
     * @param bn
     * @param bt
     * @returns any Successful Response
     * @throws ApiError
     */
    getBnDescendents(bn, bt) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bn_descendents/{bn}/{bt}',
            path: {
                'bn': bn,
                'bt': bt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Bn From Requirement
     * Removes the ALLOCATED_TO rel between a BehaviorNode and Requirement
     * @param bnId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateBnFromRequirement(bnId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/disassociate_bn_requirement/{bn_id}',
            path: {
                'bn_id': bnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bn Integrations
     * @param bn
     * @param bt
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBnIntegrations(bn, bt, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_bn_integrations/{bn}/{bt}',
            path: {
                'bn': bn,
                'bt': bt,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Bn Relationship
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBnRelationship(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_bn_relationship',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Sao Behaviour
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createSaoBehaviour(requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/create_sao_behaviour',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Integrate
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    integrate(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/integrate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    move(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/move',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Node
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyNode(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/copy_node',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Bt
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyBt(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/copy_bt',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Instantiate Interface
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    instantiateInterface(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/instantiate_interface',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Tests
     * @param bt
     * @returns any Successful Response
     * @throws ApiError
     */
    generateTests(bt) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/generate_tests/{bt}',
            path: {
                'bt': bt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Testedness
     * @param bt
     * @param model
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getTestedness(bt, model, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_testedness/{bt}',
            path: {
                'bt': bt,
            },
            query: {
                'model': model,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Enablement
     * @param bt
     * @param model
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getEnablement(bt, model, formData) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/{bt}/enablement',
            path: {
                'bt': bt,
            },
            query: {
                'model': model,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refine Behaviour
     * Refine a node (source_id) to BT (new BT called ref_name, if ref_id is None,
     * otherwise to root of BT with id = ref_id)
     * For existing BT:
     * Refines to root if function_name is blank
     * Refines to a new child abstract function if function_id is blank
     * Else refines to existing abstract function of function_id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    refineBehaviour(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/refine_behaviour',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refine New
     * Refine a node to a new BT
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    refineNew(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/refine_new',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Frs
     * @param bt
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    generateFrs(bt, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/generate_frs/{bt}',
            path: {
                'bt': bt,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Segments
     * @param bn
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSegments(bn, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviour/get_segments/{bn}',
            path: {
                'bn': bn,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Tc From Segment
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateTcFromSegment(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/generate_tc_from_segment',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bt Export
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    btExport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bt Import
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    btImport(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviour/import',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
