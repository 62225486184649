<template>
  <b-modal
    id="delete-comment-modal"
    centered
    title="Delete this comment?"
    ok-title="Delete"
    ok-variant="danger"
    cancel-variant="outline-secondary"
    @ok="deleteComment"
  >
    Once you delete, it's gone for good.
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DeleteComment',
  props: {
    parentId: {
      required: true,
      type: String,
    },
    parentLabel: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('comments', ['selected_comment']),
  },
  methods: {
    deleteComment(evt) {
      evt.preventDefault()
      const data = {
        label: this.parentLabel,
        id: this.parentId,
      }
      const commentId = this.selected_comment.id
      this.$http.delete(`/v1/legacy/comments/${commentId}`)
        .then(() => {
          this.$emit('delete-comment')
          this.$bvModal.hide('delete-comment-modal')
        })
    },
  },
}
</script>
