export class CommentsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Comments
     * @param label
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    comments(label, id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/comments',
            query: {
                'label': label,
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comments
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    comments1(model = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/comments',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComment(id, model = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComment(id, model = '') {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Comment
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComment(id, model = '') {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/comments/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
