export class ComponentsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Components
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    components(model, simple = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Functions
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    functions(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/functions',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Resources
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    resources(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/resources',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Performers
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    performers(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/performers',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Systems
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    systems(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/systems',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Capabilities
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    capabilities(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/capabilities',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Standards
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    standards(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/standards',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Objectives
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    objectives(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/objectives',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Benefits
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    benefits(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/benefits',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Kpis
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    kpis(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/kpis',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stakeholders
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    stakeholders(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/stakeholders',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Assumptions
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    assumptions(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/assumptions',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dependencies
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    dependencies(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/dependencies',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Project Constraints
     * @param model
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    projectConstraints(model, simple) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/components/project_constraints',
            query: {
                'model': model,
                'simple': simple,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
