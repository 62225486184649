export class ModelsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get User Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/user',
        });
    }
    /**
     * Get Shared Models
     * Get all models shared with the given user
     * @returns any Successful Response
     * @throws ApiError
     */
    getSharedModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/shared',
        });
    }
    /**
     * Get Public Models
     * Get all public models in the database
     * @returns any Successful Response
     * @throws ApiError
     */
    getPublicModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/public',
        });
    }
    /**
     * Get Bts
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBts(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/bts',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Model
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    exportModel(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/models/export',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Model
     * @returns any Successful Response
     * @throws ApiError
     */
    importModel() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/models/import',
        });
    }
    /**
     * List All Models
     * Return a list of all Models that the requesting User has access to.
     * @returns Model Successful Response
     * @throws ApiError
     */
    listAllModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/models',
        });
    }
    /**
     * Create Model
     * @param requestBody
     * @returns Model Successful Response
     * @throws ApiError
     */
    createModel(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/models',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Model Key
     * @param modelName
     * @returns any Successful Response
     * @throws ApiError
     */
    generateModelKey(modelName) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/models/generate-model-key',
            query: {
                'model_name': modelName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Validate Model Details
     * Check if a model's name or key already exist in a database
 * return:
 * dictionary in the format of
 * { errors: { modelName: [], ModelKey: [] } }
     * @param modelName
     * @param modelKey
     * @returns any Successful Response
     * @throws ApiError
     */
    validateModelDetails(modelName, modelKey) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/models/validate-model-details',
            query: {
                'model_name': modelName,
                'model_key': modelKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
