export class DomainModelApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Comp Tree Vue
     * @param model
     * @param partial
     * @returns any Successful Response
     * @throws ApiError
     */
    compTreeVue(model = '', partial = 'true') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_tree',
            query: {
                'model': model,
                'partial': partial,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Comp Tree Vue At Root
     * @param root
     * @param model
     * @param partial
     * @returns any Successful Response
     * @throws ApiError
     */
    compTreeVueAtRoot(root, model = '', partial = 'true') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_tree/{root}',
            path: {
                'root': root,
            },
            query: {
                'model': model,
                'partial': partial,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Next Level Vue
     * @param root
     * @returns any Successful Response
     * @throws ApiError
     */
    nextLevelVue(root) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/next_level/{root}',
            path: {
                'root': root,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Entity
     * @param entityId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    entity(entityId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/entity/{entity_id}',
            path: {
                'entity_id': entityId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComposition(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component
     * create component from json (dict)
 * NOTE (both optional):
 * labels sent as a list in the json request {.... labels: ['Label',...], }
 * parent sent in json request {..., parent: 'Parent', parent_rel: 'aggregation | inheritance', ... }
 * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponent() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/composition',
        });
    }
    /**
     * Get Components Simple
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentsSimple(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_components_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Functions
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getFunctions(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/functions',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Enablers
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getEnablers(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/enablers',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component
     * @param cpt
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponent(cpt, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition/{cpt}',
            path: {
                'cpt': cpt,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Subtree
     * @param root
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionSubtree(root, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_composition_subtree/{root}',
            path: {
                'root': root,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Subtree
     * @param model
     * @param root
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionSubtree1(model, root) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_composition_subtree',
            query: {
                'model': model,
                'root': root,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Weighted Req
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionWeightedReq(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_weighted_req',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Composition Weighted Iss
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getCompositionWeightedIss(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/composition_weighted_iss',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component By Id
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentById(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_by_id/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Components By Label
     * @param label
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentsByLabel(label, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_components_by_label/{label}',
            path: {
                'label': label,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Unallocated Components
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getUnallocatedComponents(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_unallocated_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matching Components
     * @param name
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getMatchingComponents(name, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_matching_components/{name}',
            path: {
                'name': name,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matching Components For List
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMatchingComponentsForList(model, requestBody) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_matching_components',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Fn Decomp
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getFnDecomp(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_fn_decomp',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Behaviour
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentBehaviour(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_behaviour/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Requirements
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentRequirements(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_requirements/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Issues
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentIssues(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_issues/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Component Issues
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponentIssues(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_all_component_issues',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Attributes
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentAttributes(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_attributes/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Constraints
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentConstraints(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_constraints/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Aliases
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentAliases(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_aliases/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Interfaces
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInterfaces(id, modelId = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_interfaces/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'modelId': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Instances
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInstances(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_instances/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Instance
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInstance(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/instance/{_id}',
            path: {
                '_id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Instance
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteInstance(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/instance/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Instances
     * @param componentId
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsInstances(componentId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{component_id}/instances',
            path: {
                'component_id': componentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Components Instance
     * @param componentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentsInstance(componentId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{component_id}/instances',
            path: {
                'component_id': componentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Component Instances
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponentInstances(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_all_component_instances',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Instance
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentInstance(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_instance/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Build Configurations
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    buildConfigurations(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/build_configurations',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Build Configuration
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBuildConfiguration(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/build_configurations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Build Configurations
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getBuildConfigurations(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_build_configurations',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Build Configuration
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getBuildConfiguration(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/build_configuration/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Build Configuration
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBuildConfiguration(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/build_configuration/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Build Configuration
     * @param configurationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBuildConfiguration(configurationId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/build_configuration/{configuration_id}',
            path: {
                'configuration_id': configurationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Build Configuration From Release
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createBuildConfigurationFromRelease(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_build_configuration_from_release',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Releases
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentReleases(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_releases/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Parent
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentParent(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_parent/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Sets
     * @param searchQuery
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentSets(searchQuery, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_sets',
            query: {
                'search_query': searchQuery,
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Issues
     * @param componentId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsIssues(componentId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{component_id}/issues',
            path: {
                'component_id': componentId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Components Issues
     * @param componentId
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentsIssues(componentId, model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{component_id}/issues',
            path: {
                'component_id': componentId,
            },
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Requirements
     * @param compId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsRequirements(compId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/components/{comp_id}/requirements',
            path: {
                'comp_id': compId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Components Requirements
     * @param compId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    componentsRequirements1(compId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/components/{comp_id}/requirements',
            path: {
                'comp_id': compId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Multiple Components
     * This is more of a special use case API endpoint that just simplified some JavaScript
 * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    createMultipleComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_multiple_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Impacted Fns
     * @param id
     * @param model
     * @param newName
     * @returns any Successful Response
     * @throws ApiError
     */
    getImpactedFns(id, model, newName = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_impacted_fns/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
                'new_name': newName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Rename Impacted Fns
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    renameImpactedFns(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/rename_impacted_things',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Swap Alias Name
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    swapAliasName(id, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/swap_alias_name/{_id}',
            path: {
                '_id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Component
     * @param cptId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComponent(cptId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/composition/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Component
     * @param cptId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponent(cptId, model) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/composition/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Update Components
     * Utility endpoint to bulk update components
 * :return:
     * @param model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkUpdateComponents(model, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/bulk_update_components',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Component And Requirements
     * Post request with a json structured as:
 * {
     * 'components': ['a component id', ...],
     * 'requirements':  ['R_id_1', ..., 'R-id_n']
     * }
     * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateComponentAndRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/associate_requirements_with_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Component And Releases
     * Associate component with release
     * Post request with a json structured as:
     * {
         * 'component': 'a component id',
         * 'releases':  ['a release id', ...]
         * }
         * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    associateComponentAndReleases(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/associate_releases_with_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Component From Releases
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateComponentFromReleases(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_releases_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Comp Rels
     * Create component relations
         * Requires:
         * {
             * 'name': <<relation name>>,
             * 'source': <<source component id>>,
             * 'target': <<target component id>>
             * }
             * Other key-value pairs handled for keys:
             * 'source_multiplicity', 'source_label', 'target_multiplicity', 'target_label'
             * :return:
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createCompRels(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/comp_rels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Multiple Comp Rels
     * Create component relations
             * Requires:
             * {'rels': [
                 * {
                     * 'name': <<relation name>>,
                     * 'source': <<source component id>>,
                     * 'target': <<target component id>>
                     * },
                     * ...
                     * ]}
                     * Other key-value pairs handled for keys:
                     * 'source_multiplicity', 'source_label', 'target_multiplicity', 'target_label'
                     * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    createMultipleCompRels() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_comp_rels',
        });
    }
    /**
     * Delete Component Relation
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponentRelation(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/delete_component_relation/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate Component From Requirement
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateComponentFromRequirement(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_requirements_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Disassociate All Component Requirements
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateAllComponentRequirements(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/disassociate_all_requirements_from_component',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Merge Components
     * Merge <from> component with <to> component
                     * Expecting json to be posted as { 'from': component, 'to': component }
                     * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    mergeComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/merge_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Validate Component Name
     * @returns any Successful Response
     * @throws ApiError
     */
    validateComponentName() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/validate_component_name',
        });
    }
    /**
     * Copy Components
     * Copy <cpt> to a new component with <name> under <parent> with <parent_rel_type>
                     * optionally copying <requirements> and <issues>
                     * Expecting json to be posted as
                     * {
                         * 'cpt': 'a component id',
                         * 'name': 'the new name of the copy',
                         * 'parent': 'the parent under which to put the copy - PROBLEM_SPACE by default',
                         * 'parent_rel_type': 'aggregation or inheritance',
                         * 'copy_reqs': 'True or False - False by default',
                         * 'copy_issues': 'True or False - False by default'
                         * }
                         * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    copyComponents(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/copy_component',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Component
     * Move component with <id> under parent with <parent_id> with <rel_type> of ["part_of", "type_of"]
                         * All parameters passed as posted JSON
                         * {
                             * "id": ...the id of the moving component,
                             * "parent_id": ...the parent id to move it under (defaults to PROBLEM_SPACE),
                             * "parent_rel_type": ...the type of composition ["aggregation"|"inheritance"] (defaults to "aggregation")
                             * }
                             * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    moveComponent(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/move_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Bulk Component
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    moveBulkComponent(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/move_bulk_components',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Parent Rel
     * Change the parent relationship between the component with id=<id> from TYPE_OF to PART_OF and vice versa
                             * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    changeParentRel() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/change_parent_rel',
        });
    }
    /**
     * Add Attribute
     * Add <attribute> characterised by post request json to cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addAttribute(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_attribute/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interfaces
     * Get all interfaces
                             * :return: JSON object containing all found Interfaces
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfaces(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Interface
     * Add interface to model (passed a dictionary of properties defining the interface)
                             * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    addInterface(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/interfaces',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interface
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterface(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface
     * Update <interface> details where interface._id = _id
                             * :param _id:
                             * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterface(id) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Interface
     * Delete interface with id
                             * :param _id: Unique ID of the Interface
                             * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteInterface(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/interfaces/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Reqs
     * Update <interface> requirements where interface.id = _id
                             * :param _id:
                             * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceReqs(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_interface_reqs/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Attribute
     * Delete <attribute> from cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    delAttribute() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_attribute',
        });
    }
    /**
     * Update Attribute
     * Update <attribute> details where attribute.id = attr_id
                             * pre: json request must have a 'name' key with a non null value
                             * :param attr_id:
                             * :return:
     * @param attrId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateAttribute(attrId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_attribute/{attr_id}',
            path: {
                'attr_id': attrId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Property
     * Add <property> characterised by post request json to cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addProperty(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_property/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Property
     * Delete <property> from cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    delProperty() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_property',
        });
    }
    /**
     * Update Property
     * Update <property> details where property.id = prop_id
                             * pre: json request must have a 'name' key with a non null value
                             * :param prop_id:
                             * :return:
     * @param propId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateProperty(propId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_property/{prop_id}',
            path: {
                'prop_id': propId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Constraint
     * Add <constraint> characterised by post request json to cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addConstraint(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_constraint/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Constraint To
     * Cop constraint identified by <constraint_id> to cpt with id=<cpt_id>
                             * pre: json request must have a 'constraint_id' key with a non null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    copyConstraintTo(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/copy_constraint_to/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Constraint
     * Get constraint by ID
                             * :param _id:
                             * :return:
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getConstraint(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_constraint/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Constraints
     * Add multiple <constraint>s from parsing
                             * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    addConstraints(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_constraints',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Del Constraint
     * Delete <constraint> from cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :return:
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    delConstraint(model) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/del_constraint',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Constraint
     * Update <constraint> details where constraint.id = constraint_id
                             * pre: json request must have a 'name' key with a non null value
                             * :param constraint_id:
                             * :return:
     * @param constraintId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateConstraint(constraintId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/update_constraint/{constraint_id}',
            path: {
                'constraint_id': constraintId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Compliance Requirement
     * Add <ComplianceRequirement> characterised by post request json
                             * pre: json request must have a 'reference' key with a non-null value
                             * :return:
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createComplianceRequirement(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/compliance_requirement',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Constraint From Property
     * Create a Quality Attribute Requirement (constraint)
                             * from property identified by <property_id> to cpt with id=<cpt_id>
                             * pre: json request must have a 'property_id' key with a non-null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    createConstraintFromProperty(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/create_constraint_from_property/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Compliance Requirement
     * Get ComplianceRequirement by ID
                             * :param _id:
                             * :return:
     * @param id
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComplianceRequirement(id, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_compliance_requirement/{_id}',
            path: {
                '_id': id,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Compliance Requirement
     * Update <ComplianceRequirement> details where ComplianceRequirement.id = constraint_id
                             * pre: json request must have a 'reference' key with a non null value
                             * :param compliance_req_id:
                             * :return:
     * @param complianceReqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    updateComplianceRequirement(complianceReqId, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/compliance_requirement/{compliance_req_id}',
            path: {
                'compliance_req_id': complianceReqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Compliance Requirement
     * Delete <ComplianceRequirement> node based on it's ID and Model ID.
                             * :return: Number of deleted nodes
     * @param complianceReqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComplianceRequirement(complianceReqId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/compliance_requirement/{compliance_req_id}',
            path: {
                'compliance_req_id': complianceReqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Alias
     * Add <alias> characterised by post request json to cpt with id=<cpt_id>
                             * pre: json request must have a 'name' key with a non null value
                             * :param cpt_id:
                             * :return:
     * @param cptId
     * @returns any Successful Response
     * @throws ApiError
     */
    addAlias(cptId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/add_alias/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Alias
     * Delete alias from component by <id>
                             * :param _id:
                             * :return:
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteAlias(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/domain_model/alias/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Context
     * @param cpt
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentContext(cpt, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/get_component_context/{cpt}',
            path: {
                'cpt': cpt,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Requirements From Fn
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromFn() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_fn',
        });
    }
    /**
     * Generate Requirements From If
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromIf() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_if',
        });
    }
    /**
     * Generate Requirements From Constraint
     * Generate Requirements from Constraints
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromConstraint(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_constraint',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Requirements From Compliance
     * Generate Requirements from Constraints
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    generateRequirementsFromCompliance(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_requirements_from_compliance',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Constraint Requirements From Component
     * Generate Requirements from Constraints
     * @returns any Successful Response
     * @throws ApiError
     */
    generateConstraintRequirementsFromComponent() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/generate_constraint_requirements_from_component',
        });
    }
    /**
     * Allocate Function
     * @returns any Successful Response
     * @throws ApiError
     */
    allocateFunction() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/allocate_function',
        });
    }
    /**
     * Constrainfunction
     * @returns any Successful Response
     * @throws ApiError
     */
    constrainFunction() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/constrain_function',
        });
    }
    /**
     * Import Model
     * @returns any Successful Response
     * @throws ApiError
     */
    importModel() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_subtree',
        });
    }
    /**
     * Export Uml
     * @returns any Successful Response
     * @throws ApiError
     */
    exportUml() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_uml',
        });
    }
    /**
     * Export Trl
     * @returns any Successful Response
     * @throws ApiError
     */
    exportTrl() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_trl',
        });
    }
    /**
     * Import Static Uml
     * @returns any Successful Response
     * @throws ApiError
     */
    importStaticUml() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_static_uml',
        });
    }
    /**
     * Export Performers Report
     * @returns any Successful Response
     * @throws ApiError
     */
    exportPerformersReport() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_performers_report',
        });
    }
    /**
     * Get Report Data
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getReportData(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/performers_report',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Subtree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    exportSubtree(modelId = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/export_subtree',
            query: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Subtree
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    importSubtree(modelId = '') {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/import_subtree_json',
            query: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Convert Entity To Attribute
     * Convert a Component (Entity) to an Attribute of another Component
     * @returns any Successful Response
     * @throws ApiError
     */
    convertEntityToAttribute() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/convert_entity_to_attribute',
        });
    }
    /**
     * Get Compliance For Spec
     * @param cptId
     * @param specId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getComplianceForSpec(cptId, specId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/domain_model/compliance/{cpt_id}/{spec_id}',
            path: {
                'cpt_id': cptId,
                'spec_id': specId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Record Compliance For Req
     * Record a Compliance Record for Component and Requirement
     * @returns any Successful Response
     * @throws ApiError
     */
    recordComplianceForReq() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/domain_model/record_compliance_for_req',
        });
    }
}
