export class UtilsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Details
     * @returns any Successful Response
     * @throws ApiError
     */
    details() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/utils/details',
        });
    }
    /**
     * Ping
     * @returns any Successful Response
     * @throws ApiError
     */
    ping() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/utils/ping',
        });
    }
    /**
     * Ping
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    ping1(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/utils/ping',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Details
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    details1(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/utils/details',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
