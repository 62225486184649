export class BehaviourTreesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List All
     * Get all Behaviour Trees in Model
 * :return: JSON object representation of all found Behaviour Trees
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listAll(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create
     * Create a Behaviour Tree
 * :return: JSON object representation of the created Behaviour Tree
     * @param requestBody
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    create(requestBody, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviourTrees',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List All Metadata
     * Get all metadata for Behaviour Trees in Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listAllMetadata(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees/metadata',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    get(btId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    delete(btId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * @param btId
     * @returns any Successful Response
     * @throws ApiError
     */
    update(btId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/legacy/behaviourTrees/{bt_id}',
            path: {
                'bt_id': btId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy
     * Copy the Behaviour Tree with id {bt_id}
     * @param btId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    copy(btId, modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/behaviourTrees/{bt_id}/copy',
            path: {
                'bt_id': btId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
