export class InterfacesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Interfaces Simple
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfacesSimple(model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/get_interfaces_simple',
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Interfaces
     * Get all interfaces with queries, and less wait
     * @param model
     * @param q
     * @param status
     * @param type
     * @param irl
     * @param sortBy
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllInterfaces(model, q = '', status = '', type = '', irl = '', sortBy = '') {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces',
            query: {
                'model': model,
                'q': q,
                'status': status,
                'type': type,
                'irl': irl,
                'sortBy': sortBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Interface
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterface() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces',
        });
    }
    /**
     * Create Interface
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterface1() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/create',
        });
    }
    /**
     * Interface
     * @param interfaceId
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    interface(interfaceId, model) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/{interface_id}',
            path: {
                'interface_id': interfaceId,
            },
            query: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Interface
     * @param interfaceId
     * @returns any Successful Response
     * @throws ApiError
     */
    interface1(interfaceId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/legacy/interfaces/{interface_id}',
            path: {
                'interface_id': interfaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    update(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Reqs
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceReqs(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_reqs/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Fns
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceFns(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_fns/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Interface Standards
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    updateInterfaceStandards(id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/update_interface_standards/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Interface Releases
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getInterfaceReleases(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legacy/interfaces/get_interface_releases/{_id}',
            path: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Associate Interface And Releases
     * Associate interface with release
 * Post request with a json structured as:
 * {
     * 'interface': 'a interface id',
     * 'releases':  ['a release id', ...]
     * }
     * :return:
     * @returns any Successful Response
     * @throws ApiError
     */
    associateInterfaceAndReleases() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/associate_releases_with_interface',
        });
    }
    /**
     * Disassociate Interface From Releases
     * @returns any Successful Response
     * @throws ApiError
     */
    disassociateInterfaceFromReleases() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/disassociate_releases_from_interface',
        });
    }
    /**
     * Export If Report
     * @returns any Successful Response
     * @throws ApiError
     */
    exportIfReport() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legacy/interfaces/report',
        });
    }
}
